<template>
  <el-table-column v-bind="item">
    <template slot="header">
      <span>
        {{ item.label }}
      </span>
    </template>
    <template v-for="(column, index) in item.children">
      <sh-table-column :key="index" :item="column" v-if="column.children"> </sh-table-column>
      <el-table-column v-bind="column" v-else :key="index">
        <template slot="header">
          <span>
            {{ column.label }}
          </span>
        </template>
        <template v-slot="scope" v-if="column.scopeSlot">
          <slot
            :name="column.scopeSlot"
            :row="scope.row"
            :index="scope.$index"
          ></slot>
        </template>
        <template v-else-if="column.render" v-slot="scope">
          <render-dom
            :render="() => column.render(scope.row, scope.$index)"
          ></render-dom>
        </template>
      </el-table-column>
    </template>
  </el-table-column>
</template>

<script>
export default {
  name: "ShTableColumn",
  components: {
    renderDom: {
      functional: true,
      props: {
        render: Function,
      },
      render(createElement, renDom) {
        return <div>{renDom.props.render()}</div>;
      },
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return { prop: "", label: "", align: "center" };
      },
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>