import {
  setToken,
  removeToken,
  setRefreshToken,
  setTokenType
} from "@/utils/storage";

import { login, LoginBySms, getInfo } from "@/api/login";
import { welcome } from "@/utils/util";
const defaultAvatar =require("@/assets/images/user.png");
const state = {
  userInfo: null,
  token: "",
  tokenType: "",
  loginType: "system",
  roles: [],
  firstLogin: false
};
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, { name, welcome }) => {
    state.name = name;
    state.welcome = welcome;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_INFO: (state, info) => {
    state.userInfo = info;
  },
  SET_LOGIN_TYPE: (state, LoginType) => {
    state.LoginType = LoginType;
  },
  SET_FIRST_LOGIN: (state, firstLogin) => {
    state.firstLogin = firstLogin;
  },
  SET_TOKEN_TYPE: (state, tokenType) => {
    state.tokenType = tokenType;
  }
};
const actions = {
  // 登录
  Login({ commit }, params) {
    return new Promise((resolve, reject) => {
      const { loginType } = params;
      delete params.loginType;
      let loginFunc = null;
      if (loginType == 2) {
        //验证码登录
        loginFunc = LoginBySms;
      } else if (loginType == 1) {
        //账号登录
        loginFunc = login;
      }
      loginFunc(params)
        .then((data) => {
          console.log(data)
          //存入storage,下次打开网站自动登录
          setToken(data.token);
          // setTokenType(data.token_type);
          // setRefreshToken(data.refresh_token); 
          //存入状态管理，页面刷新时使用
          commit("SET_TOKEN", data.token);
          // commit("SET_TOKEN_TYPE", data.token_type);
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  //扫码登录
  ScanCodeLogin({ commit }, data) {
    return new Promise((resolve,reject)=>{
        setToken(data.token);
        setTokenType(data.token_type);
        setRefreshToken(data.refresh_token);
        //存入状态管理，页面刷新时使用
        commit("SET_TOKEN", data.token);
        commit("SET_TOKEN_TYPE", data.token_type);
        resolve(data);
    })
 
  },
  // 获取用户信息
  GetInfo({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((response) => {
          // 如果用户是第一次登录
          // commit('SET_FIRST_LOGIN', response.object.firstLogin || false) // 后端没有修改密码接口之前注释
          const result = {};
          result.role = {
            permissions: []
          };
          response.avatar = response.avatar || defaultAvatar;
          sessionStorage.setItem("userId", response.identifier);
          commit("SET_ROLES", result);
          commit("SET_INFO", response);
          commit("SET_NAME", {
            name: response.name,
            welcome: welcome()
          });
          // commit('SET_AVATAR', response.data.avatar || defaultAvatar)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 登出
  Logout({ commit, state }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      commit("SET_ROLES", []);
      commit('SET_ROUTE',[])
      removeToken();
      resolve();
    });
  }
};

export default {
  state,
  mutations,
  actions
};
