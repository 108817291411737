<template>
  <div class="app-main">
    <el-scrollbar>
      <sh-keep-alive :include="includes">
        <router-view :key="$route.fullPath"></router-view>
      </sh-keep-alive>
  </el-scrollbar>
  </div>
</template>
<script>
import ShKeepAlive from "@/components/keepAlive.js";
export default {
  name: "AppMain",
  data() {
    return {};
  },
  computed: {
    includes() {
      return this.$store.state.navtab.keepComponents;
    }
  },
  components: { ShKeepAlive },
  methods: {}
};
</script>

<style lang="scss" scoped>
.app-main {
  padding: 20px;
  background: #f1f1f1;
  height: calc(100% - 90px);
  overflow-y: auto;
}
</style>
