<template>
  <el-select
    :value="value"
    filterable
    remote
    clearable
    :remote-method="getList"
    @change="onChange"
    :placeholder="placeholder"
    style="width: 100%"
  >
    <el-option
      :label="item[keys]"
      :value="item.id"
      v-for="item in list"
      :key="item.id"
    >
    <slot  :item="item"></slot>
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "ShSelect",
  props: {
    placeholder:{
      type:String,
      default:'请选择'
    },
    value: {
      type: [Number, String],
      default: 0,
    },
     dataMethod: {
      type: Function,
      required: true
    },
    params:{
      type:Object,
      default(){
        return {}
      }
    },
    keys:{
      type:String,
      default:'name'
    }
  },
  model: {
    prop: "value",
    event: "change",
  },

  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    //获取客户列表
    getList(event = "") {
      
      this.dataMethod({ keyword: event,...this.params }).then((res) => {
        this.list = res;
      });
    },
    onChange(event) {
      this.$emit("change", event);
    },
  },
};
</script>