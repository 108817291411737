import request from '@/utils/request'

const userApi = {
  Login: '/user/login',
  BaseInfo:"/user/info",
  UserMenu: '/user/menu',

  LoginBySms: '/login-by-sms/',
  Logout: '/system/logout',
  ForgePassword: '/auth/forge-password',
  SendSms: '/send-captcha/',
  UserInfo: '/employees/info',
 

  Authorization: `Basic bGxtLWFkbWluOmFkbWluMTIzNDU2Nzg=`,
 
}
export  const  generateQrCode= "/server/generate-qr-code/"  
/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  return request({
    url: userApi.Login,
    method: 'post',
    // headers: {
    //   Authorization: userApi.Authorization
    // },
    data: parameter
  })
}

export function LoginBySms(parameter) {
  return request({
    url: userApi.LoginBySms,
    method: 'post',
    data: parameter
  })
}

export function getSmsCaptcha(parameter) {
  return request({
    url: userApi.SendSms,
    method: 'post',
    data: parameter
  })
}

export function getInfo() {
  return request({
    url: userApi.BaseInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getCurrentUserNav() {
  return request({
    url: userApi.UserMenu,
    method: 'get'
  })
}

export function logout() {
  return request({
    url: userApi.Logout,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

