import request from '@/utils/request'

const api = {
  delete: '/sys/file/delete',
  positive: '/sys/file/download/positive/',
  download: '/sys/file/download/zip/',
  upload: '/upload',
  attachments: '/attachments/',
  textUpload: '/sys/file/upload/text',
  deleteMultiple: '/llm/multipleImages/delete',
  generate: '/sys/file/generate',
  deleteBatch: '/sys/file/deleteBatch',
  getpolicy: '/sys/file/getpolicy',

}


// 资源删除
export function deleteFile(parameter) {
  return request({
    url: `${api.attachments}${parameter.id}/`,
    header: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'DELETE',
  })
}
// 资源批量删除
export function patchDeleteFile(parameter) {
  return request({
    url: `${api.attachments}by-ids/`,
    header: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'DELETE',
    params:parameter
  })
}
//查询单个文件
export function FileInfo(parameter) {
  return request({
    url: `${api.attachments}${parameter.id}/`,
    header: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'GET',
  })
}
//按bizcode查询文件
export function getFilesByBizCode(parameter) {
  return request({
    url: `${api.attachments}`,
    header: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'GET',
    params:parameter
  })
}
// 正片资料下载(-直接下载文件)
// export function download(parameter) {
//   return request({
//     url: api.download + parameter.id,
//     header: {
//       'Content-Type': 'application/json;application/octet-stream'
//     },
//     timeout: 3600000,
//     responseType: 'blob',
//     method: 'GET',
//     params: {}
//   })
// }


// 正片资料下载(-获取需要下载的文件列表)
export function download(parameter) {
  return request({
    url: api.download + parameter.id,
    header: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'GET',
    params: {}
  })
}


// 媒体文件上传
export function upload(parameter, callBack) {
  const {
    file,
    code,
    mark
  } = parameter
  return request({
    url: api.upload,
    header: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: function (progressEvent) { //上传进度
      callBack(progressEvent)
    },
    timeout: 3600000,
    method: 'POST',
    data: file,
    params: {
      code,
      mark
    }
  })
}

// 文本文件上传
export function textUpload(parameter) {
  return request({
    url: api.textUpload,
    header: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    params: parameter
  })
}

// 根据详情图id删除图片
export function deleteMultiple(parameter) {
  return request({
    url: api.deleteMultiple,
    method: 'delete',
    data: parameter
  })
}

// 预生成样片或套餐唯一ID
export function generate() {
  return request({
    url: api.generate,
    method: 'GET',
    header: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: {}
  })
}

// OSS资源批量删除
export function deleteBatch(parameter) {
  return request({
    url: api.deleteBatch,
    method: 'DELETE',
    header: {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'data-Type': 'json'
    },
    data: parameter
  })
}


//OSS上传获取签名
export function getpolicy(parameter) {
  return request({
    url: api.getpolicy,
    header: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'GET',
    params: parameter
  })
}



//直传OSS
export function uploadOSS(url, data, source, callBack) {
  return request({
    url,
    timeout: 3600000,
    // `onUploadProgress` 允许为上传处理进度事件
    onUploadProgress: function (progressEvent) { //上传进度
      callBack(progressEvent)
    },
    cancelToken: source.token,
    method: 'POST',
    data: data
  })
}