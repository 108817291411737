/**
 *
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  // const valid_map = ['admin', 'editor']
  // return valid_map.indexOf(str.trim()) >= 0
  return /^1[3456789]\d{9}$/.test(str) //登录用户是手机号
}

//验证手机号
export function validPhone(value) {
  return /^1[3456789]\d{9}$/.test(value)
}



//验证身份证
export function validIdCard(value) {
  let length = value.length
  let reg
  if (length == 18) {
    reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)/;
  } else if (length == 15) {
    reg = /(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/;
  } else {
    return false
  }
  return reg.test(value);
}


//十六进制
export const reg16 = function (val) {
  const reg = /^[A-Fa-f0-9]{1,4}$/
  return reg.test(val)
}

//过滤特殊字符
export function filterSpecialChar(value) {
  return value.replace(/[`~!！@#￥$%——./<>?《》？，。、……^&*（）()_=+-, ]/g, '')
}

//只能输入字母和数字
export function filterLetterNmber(value) {
  return value.replace(/[\u4e00-\u9fa5`~!！@#￥$%——./<>?《》？，。、……^&*（）()_=+-, ]/g, '')
}
//检测账号（中英数 至少输入两种）
export function validAccount(value) {
  const variations = [{
    key: 'ch',
    flag: /[\u4e00-\u9fa5]/.test(value)
  }, {
    key: 'en',
    flag: /[a-zA-Z]/.test(value)
  }, {
    key: 'nu',
    flag: /\d/.test(value)
  }]
  const temp = variations.filter(item => item.flag)
  return temp.length>=2
}
//校验中英数
export function validChAndEnAndNu(value) {
  const reg = /^[\a-\z\A-\Z0-9\u4e00-\u9fa5]+$/
  return reg.test(value)
}
//校验中文
export function validCH(value) {
  const reg = /[\u4e00-\u9fa5]$/
  return reg.test(value)
}

//校验英文
export function validEn(value) {
  const reg = /[\a-\z\A-\Z]$/
  return reg.test(value)
}

//过滤空白字符
export function filterSpace(value) {
  return value.replace(/(\s*$)/g, '')
}

//过滤非数字
export function filterNotNumber(value) {
  return value.replace(/[^\d]/g, '')
}
