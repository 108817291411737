import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import router from "@/router";
import { getToken, removeToken, getTokenType } from "./storage.js";
import { Loading } from "element-ui";
console.log(process.env.VUE_APP_API_BASE_URL)
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
});
let loadingInstance;

// 异常拦截处理器
const errorHandler = (error) => {
  // hiddenLoading()
  const { code, message } =  error.data;
  if (code >= 400) {
    if (code === 401) {
      // MessageBox.close()
      if(loadingInstance){
        loadingInstance.close()
      }
      loadingInstance=   MessageBox.confirm("token已过期，请重新登录", "确认登录", {
        confirmButtonText: "重新登陆",
        showCancelButton: false,
        showClose: false,
        type: "warning"
      }).then(() => {
        store.dispatch("Logout").then(() => {
          console.log(router);
          router.replace({
            path: "/login"
            // query: {
            //   redirect: router.app._route.fullPath
            // }
          });
        });
      });
    } else if (code === 406) {
      MessageBox.confirm(message, "确认登录", {
        confirmButtonText: "重新登陆",
        showCancelButton: false,
        showClose: false,
        type: "warning"
      }).then(() => {
        store.dispatch("Logout").then(() => {
          router.replace({
            path: "/login"
            // query: {
            //   redirect: router.app._route.fullPath
            // }
          });
        });
      });
    } else {
      Message({
        message,
        type: "error"
      });
    }
    return Promise.reject(error.data);
  } else {
    Message({
      message,
      type: "error"
    });
    return Promise.reject(error);
  }
};

//网络异常拦截器
const requestErrorHandler=(error)=>{
  console.log(error)
  const {code,message}=error
  Message.error(message);
  return Promise.reject(message);
}
// request interceptor
service.interceptors.request.use(
  (config) => {
    // 将所有0或空字符串转成null
    if (
      config.method.toUpperCase() === "POST" ||
      config.method.toUpperCase() === "PUT"
    ) {
      // config.data = formatParams(config.data);
      for (let key in config.data) {
        //  config.data[key] =config.data[key]!==''? config.data[key] : null
         if(!config.data[key] && config.data[key]!==0){
          delete config.data[key]
         }
      }
    }
    const token = getToken() || (store && store.getters.token);
    // const tokenType = getTokenType() || (store && store.getters.tokenType);
    // do something before request is sent
    // showLoading()
    if (token) {
      config.headers["token"] = `${token}`;
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use((response) => {
  if (response.data.code == 200 ) {
    // Message({
    //   message:response.data.message,
    //   type: "success"
    // });
    return Promise.resolve(response.data.data);
  } else {
    return errorHandler(response);
  }
}, requestErrorHandler);

export default service;
