<template>
  <div v-if="!item.meta.hidden">

    <el-submenu v-if="route.children && route.children.length>1" ref="subMenu" :index="route.path" popper-append-to-body>
      <template slot="title">
        <item
          v-if="route.meta"
          :icon="route.meta && route.meta.icon"
          :title="route.meta.title"
        />
      </template>
      <AppMeunItem
        v-for="(child, index) in route.children"
        :key="index"
        :is-nest="true"
        :item="child"
        :base-path="child.path"
        class="nest-menu"
      />
    </el-submenu>
    <app-link v-else :to="route.path">
      <el-menu-item
        :index="route.path"
        :class="{ 'submenu-title-noDropdown': !isNest }"
      >
        <item
          :icon="route.meta.icon"
          :title="route.meta.title"
        />
      </el-menu-item>
    </app-link>
  </div>
</template>

<script>
import path from "path";
import { isExternal } from "@/utils/validate";
import Item from "./Item";
import AppLink from "./Link";
import FixiOSBug from "./FixiOSBug";
export default {
  name: "AppMeunItem",
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ""
    }
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    return {
      onlyOneChild: null
    };
  },
  computed: {
    route() {
      if (this.item.children && this.item.children.length == 1) {
        return this.item.children[0];
      } else {
        return this.item;
      }
    }
  },
  methods: {
    // generateTitle,
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.meta.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
        return true;
      }
      return false;
    }
  }
};
</script>
