<template>
  <div>
    <div class="bgfff tabBox" ref="tabBox">
      <div class="btns" v-if="showBtns">
        <div class="prevBtn" @click="handlePrev"></div>
        <div class="nextBtn" @click="handleNext"></div>
      </div>
      <!-- <el-tabs
      class="flex1"
      type="card"
      :value="aliveRoute"
      @tab-remove="removeTab"
      @tab-click="clickTab"
    >
      <el-tab-pane
        :closable="routes.length > 1"
        :key="index"
        v-for="(item, index) in routes"
        :name="item.fullPath"
      >
        <template slot="label">
          <div
            @blur="
              visible = false;
              rightClickTab = '';
            "
            style="display: inline-block"
            @contextmenu.prevent.stop="onMouseRight($event, item)"
          >
            {{ item.meta.title | filterMetaTitle(item, pageType) }}
          </div>
        </template>
      </el-tab-pane>
    </el-tabs> -->
      <div class="sh_tab" ref="shTab">
        <div
          @contextmenu.prevent.stop="onMouseRight($event, item)"
          class="sh_tab_item"
          :class="{ 'active-tab': aliveRoute == item.fullPath }"
          :key="index"
          ref="aliveRoute"
          v-for="(item, index) in routes"
          @click="onClick(item)"
        >
          <span> {{ item.meta.title | filterMetaTitle(item, pageType) }}</span>
          <span
            class="close-shape"
            v-if="routes.length > 1"
            @click="removeTab(item.fullPath)"
          ></span>
        </div>
      </div>
      
    </div>
    <div class="meunPop" :style="{ left, top }" v-if="visible">
        <TabMenu
          :fullPath="$route.fullPath"
          @change="
            visible = false;
            rightClickTab = '';
          "
        />
      </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import TabMenu from "./tabMeun.vue";
export default {
  name: "NavTabs",
  computed: {
    ...mapGetters(["aliveRoute", "routes", "pageType", "sidebar"]),
    aliveIndex() {
      return this.routes.findIndex((item) => item.fullPath == this.aliveRoute);
    }
  },
  created() {},
  mounted() {
    this.getShowBtns();
    this.getTransformX();
  },
  updated() {
    this.getShowBtns();
    this.getTransformX();
  },
  components: { TabMenu },
  data() {
    return {
      transformX: 0,
      maxScroll: 0,
      left: 0,
      top: 0,
      visible: false,
      rightClickTab: "",
      showBtns: false
    };
  },

  filters: {
    filterMetaTitle(e, route, pageType) {
      const {
        params: { type, name }
      } = route;
      let title = e;
      if (type) {
        title = type=='preview'?e+pageType.get(type)  :pageType.get(type) + e;
      }
      if (name) {
        title = title + "-" + name;
      }
      return title;
    }
  },
  methods: {
    ...mapActions(["remove_route", "change_alive_route"]),
    getTransformX() {
      console.log(this.aliveIndex)
      if(this.aliveIndex<0){return}
      const activeTabOffset =
        this.$refs.aliveRoute[this.aliveIndex].offsetLeft +
        this.$refs.aliveRoute[this.aliveIndex].offsetWidth;
      const BoxWidth = this.$refs.tabBox.clientWidth - 24;
      if (activeTabOffset > BoxWidth) {
        this.transformX = activeTabOffset - BoxWidth;
      } else {
        this.transformX = 0;
      }
      this.$refs.shTab.style.transform = `translateX(${-this.transformX}px)`;
    },
    getShowBtns() {
      if (
        this.$refs.shTab &&
        this.$refs.tabBox &&
        this.$refs.shTab.clientWidth > this.$refs.tabBox.clientWidth
      ) {
        this.maxScroll =
          this.$refs.shTab.clientWidth - this.$refs.tabBox.clientWidth + 24;

        this.showBtns = true;
      } else {
        this.maxScroll = 0;
        this.transformX = 0;
        this.$refs.shTab.style.transform = `translateX(${this.transformX}px)`;
        this.showBtns = false;
      }
    },
    removeTab(e) {
      this.remove_route(e);
      if (e === this.$route.fullPath) {
        //关闭当前页面
        this.$router.push(this.routes[this.routes.length - 1].fullPath);
      }
    },
    clickTab(e) {
      this.change_alive_route(e.name);
      this.$router.push(e.name);
    },
    onClick(e) {
      console.log(e);
      this.change_alive_route(e.fullPath);
      this.$router.push(e.fullPath);
    },
    onMouseRight(e, item) {
      this.visible = false;
      const w = this.sidebar.opened ? 250 : 50;
      const { clientX, clientY } = e;
      this.left = clientX - w + "px";
      this.top = clientY + "px";
      e.preventDefault();
      this.$router.push(item.fullPath);
      this.visible = true;
      document.addEventListener("click", this.hideTabMenu); //添加监听事件
      console.log(this.visible);
    },
    hideTabMenu() {
      this.visible = false;
      document.removeEventListener("click", this.hideTabMenu); //关掉监听
    },
    handlePrev() {
      console.log(this.transformX);
      this.transformX = this.transformX - 100;
      if (this.transformX < 0) {
        this.transformX = 0;
      }
      this.$refs.shTab.style.transform = `translateX(${-this.transformX}px)`;
    },
    handleNext() {
      console.log(this.transformX);
      this.transformX = this.transformX + 100;
      if (this.transformX > this.maxScroll) {
        this.transformX = this.maxScroll;
      }
      this.$refs.shTab.style.transform = `translateX(${-this.transformX}px)`;
    }
  }
};
</script>
<style scoped lang="scss">
.meunPop {
  width: 200px;
  position: absolute;
}
.tabBox {
  overflow-x: auto;
  padding: 0 14px;
  width: 100%;
  position: relative;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
}
.sh_tab {
  font-size: 12px;
  height: 40px;
  transition: all 0.3s;
  display: inline-block;
  position: relative;
  white-space: nowrap;
}
.sh_tab_item {
  display: inline-block;
  vertical-align: middle;
  padding: 10px 24px 10px 15px;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid #f0f0f0;
  color: #333;
  position: relative;
  &.active-tab {
    background: #fff;
    color: $--color-primary;
    &:before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 5px;
      background: $--color-primary;
      position: absolute;
      left: 5px;
      top: 13px;
    }
  }
  .close-shape {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 10px;
    // background:#c4c4c4;
    &:before,
    &:after {
      content: "";
      width: 1px;
      height: 9px;
      background: #a4a4a4;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
.btns {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
}
.prevBtn,
.nextBtn {
  vertical-align: middle;
  display: inline-block;
  height: 34px;
  width: 10px;
  position: absolute;
  overflow: hidden;
  background: #d1d1d1;
  &::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
  }
}
.nextBtn {
  right: 0;
}
.prevBtn::before {
  border-left-color: #a4a4a4;
  border-bottom-color: #a4a4a4;
  transform: rotate(45deg);
  left: 5px;
}
.nextBtn::before {
  border-top-color: #a4a4a4;
  border-right-color: #a4a4a4;
  transform: rotate(45deg);
  right: 5px;
}
</style>
