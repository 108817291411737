import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
Vue.use(Vuex)
// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file

function getModule() {
  let modules = {}
  modulesFiles.keys().map(item => {
    const moduleName = item.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(item)
    modules[moduleName] = value.default
  })
  return modules
}

const store = new Vuex.Store({
  modules: getModule(),
  state: {},
  mutations: {},
  actions: {},
  getters
})
export default store