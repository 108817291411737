import { roleList } from "@/api/roles";
import { getOrgTree } from "@/api/partment";
import { dictList, dictDataList } from "@/api/dictionary";
import { clearEmptyChildren } from "@/utils/util";
import { meunList } from "@/api/system";
import { createImgByName } from "@/utils/util";
import { menusToTree } from "@/utils/menu";
async function getDictData(dictType) {
  try {
    const { code, data } = await dictDataList({
      dictType
    });
    if (code == 200) {
      return data;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
}
function createAvatar(list) {
  list.forEach((item) => {
    if (item.type) {
      item.avatar = require("../../assets/images/partment.png");
    } else {
      item.avatar = createImgByName(item.name);
    }
    if (item.children && item.children.length) {
      createAvatar(item.children);
    }
    if (item.empList && item.empList.length) {
      createAvatar(item.empList);
    }
  });
}
function setAllDisabled(list) {
  list.forEach((item) => {
    item.disabled = true;
    if (item.children) {
      item.children = setAllDisabled(item.children);
    }
  });
  return list;
}
function findDisable(list) {
  for (const index in list) {
    if (list[index].status != "NORMAL") {
      list[index].disabled = true;
      if (list[index].children) {
        list[index].children = setAllDisabled(list[index].children);
      }
      return list;
    } else if (list[index].children) {
      findDisable(list[index].children);
    }
  }
  return list;
}
function listToTree(list, tree, parentId) {
  list.forEach((item) => {
    // 判断是否为父级菜单
    if (item.parentId === parentId) {
      const child = {
        ...item,
        children: []
      };
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      listToTree(list, child.children, item.id);
      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children;
      }
      // 加入到树中
      tree.push(child);
    }
  });
}
const system = {
  state: {
    sysRoles: [],
    partments: [],
    meuns: [],
    dictions: null,
    agent: [],
    pageType: new Map([
      ["create", "新增"],
      ["edit", "编辑"],
      ["preview", "详情"]
    ])
  },
  mutations: {
    SET_SYS_ROLES: (state, roles) => {
      state.sysRoles = roles;
    },
    SET_SYS_PARTMENT: (state, partments) => {
      state.partments = partments;
    },
    SET_SYS_MEUNS: (state, meuns) => {
      state.meuns = meuns;
    },
    SET_SYS_DICTIONS: (state, dictions) => {
      state.dictions = dictions;
    },
    SET_SYS_AGENT: (state, agent) => {
      state.agent = agent;
    }
  },
  actions: {
    //获取系统角色
    get_sys_roles({ commit }, status) {
      roleList({
        status
      }).then((res) => {
        commit("SET_SYS_ROLES", res);
      }).catch(err=>{
        console.log(err)
      });
    },
    //获取系统可用部门
    get_sys_partment({ commit }, status = "") {
      getOrgTree({ status }).then((res) => {
        let tempSysPartment = findDisable([res]);
        createAvatar(tempSysPartment);
        commit("SET_SYS_PARTMENT", clearEmptyChildren(tempSysPartment));
      }).catch(err=>{
        console.log(err)
      });
    },
    //获取系统权限列表
    get_sys_meuns({ commit }) {
      meunList().then((res) => {
        const data = menusToTree(res);
        commit("SET_SYS_MEUNS", data);
      }).catch(err=>{
        console.log(err)
      });
    },

    // //获取系统字典列表
    get_sys_dictions({ commit }) {
      dictList().then((res) => {
        const dics = res;
        const disArr = {};
        dics.map((item) => {
          disArr[item.code] = item.children;
        });
        commit("SET_SYS_DICTIONS", disArr);
      }).catch(err=>{
        console.log(err)
      });
    }
  }
};

export default system;
