<template>
  <div>
    <div class="list">
      <draggable
        v-model="fileList"
        group="img"
        draggable=".item"
        @update="change"
        style="display: inline-block"
      >
        <div
          class="item"
          :style="{ width: width + 'px', height: height + 'px' }"
          v-for="(file, index) in fileList"
          :key="index"
        >
          <div class="btnsMark" :style="{ lineHeight: height + 'px' }">
            <div class="btns" v-if="!disabled">
              <i
                style="margin-right: 10px"
                class="el-icon-view"
                v-if="accept == 'image' || accept == 'video'"
                @click="handlePreview(file, index)"
              />
              <i class="el-icon-delete" @click="handleRemove(file, index)" />
            </div>
          </div>
          <div v-if="file.status">
            <div class="status" v-if="file.status == 'success'">
              {{ file.status }}
            </div>
            <div v-else-if="file.status == 'loading'" class="precessBox">
              <el-progress
                type="circle"
                :percentage="file.press"
                :width="width - 20"
              >
                <template #format="percent">
                  <span style="color: #fff">{{ percent }}%</span>
                </template>
              </el-progress>
            </div>
            <div class="status statusErr" v-else>{{ file.status }}</div>
          </div>
          <img
            v-if="file.type.includes('video') || file.type.includes('image')"
            alt="example"
            style="width: 100%"
            :src=" file.url"
            class="avatar"
          />
          <p
            v-else
            style="
              display: flex;
              align-items: center;
              height: 100%;
              line-height: 1;
            "
          >
            {{ file.fileName }}上传成功
          </p>
        </div>

        <div
          slot="footer"
          class="uploadBtn"
          :style="{ width: width + 'px', height: height + 'px' }"
          v-if="(limit > 0 ? fileList.length < limit : true) && !disabled"
          @click.stop="handleClick($event)"
        >
          <input
            type="file"
            ref="fileRef"
            id="files"
            :multiple="multiple"
            @change="handleChange"
            :accept="accept + '/*'"
          />
          <div class="tac upload_btn_content">
            <i class="el-icon-plus" />
            <p class="ant-upload-text" v-if="uploadText">{{ uploadText }}</p>
          </div>
        </div>
      </draggable>
      <div class="c999">{{ tips }}</div>
    </div>
    <!-- 图片预览 -->
    <el-dialog
    append-to-body
      :visible.sync="previewVisible"
      :footer="null"
      @cancel="handleCancel"
      v-if="accept == 'image'"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </el-dialog>
    <!-- 视频预览 -->
    <el-dialog
    append-to-body
      :visible.sync="videoVisiabel"
      :footer="null"
      @cancel="videoVisiabel = false"
      v-if="accept == 'video' && videoVisiabel"
    >
      <video :src="previewVideo" class="maxImg" muted autoplay controls></video>
    </el-dialog>
  </div>
</template>
<script>
import { upload, FileInfo, patchDeleteFile } from "@/api/file";
import axios from "axios";
var CancelToken = axios.CancelToken;
import draggable from "vuedraggable";
function CreateFile(options) {
  const {
    id = "",
    fileName,
    url,
    type,
    status = "loading",
    press = 0,
  } = options;
  return {
    id,
    status,
    fileName,
    action: 0, //0--原始数据， 1--新上传的文件 （用于判断取消表单时是否删除 新上传的文件）
    url: url,
    type: type,
    press,
    source: CancelToken.source(), //定义取消上传的方法
  };
}
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    tips: {
      type: String,
      default: "",
    },

    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
    limit: {
      type: Number,
      default: 5,
    },
    size: {
      type: Number,
      default: 0,
    },
    accept: {
      type: String,
      default: "image",
    },
    code: {
      type: Number,
      default: 1,
    },
    name: {
      type: String,
      default: "test",
    },
    uploadText: {
      type: String,
      default: "上传图片",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    files: {
      type: [Array, String, Object],
      default() {
        return [];
      },
    },
  },
  model: {
    prop: "files",
    event: "change",
  },
  data() {
    return {
      videoVisiabel: false,
      previewVideo: "",
      previewVisible: false,
      previewImage: "",
      deleteIds: [],
      fileList: [],
    };
  },
  components: {
    draggable,
  },
  watch: {
    files: {
      deep: true,
      immediate: true,
      handler: async function (newV) {
        if(!newV) return
        if (this.multiple) {
          //遍历新的files,如果file对象的id  不包含于this.fileList,则将这个file对象构造成一个图片对象，插入到fileList
          newV.map((item) => {
            const flag = this.fileList.some((file) => {
              return (file.url == item.url);
            });
            if (!flag) {
              const tempFile = new CreateFile({
                url: item.url,
                type: this.accept,
                ...{ press: 100, status: "success" },
              });
              this.fileList.push(tempFile);
            }
          });
        } else {
          const flag = this.fileList.some((file) => {
            return (file.id = newV.id);
          });
          if (!flag && newV.id) {
            const tempFile = new CreateFile({
              url: newV.url,
              ...{ press: 100, status: "success" },
            });
            this.fileList = tempFile ? [tempFile] : [];
          }
        }
      },
    },
  },
  destroyed() {
    // console.log('组件已销毁')
  },
  beforeDestroy() {
    // console.log('组件销毁前，重置数据')
    this.fileList = [];
    this.deleteIds = [];
  },
  mounted() {
    //为了防止火狐浏览器拖拽的时候以新标签打开，此代码真实有效
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    //关闭视频预览
    closePreViewVideo() {
      this.videoVisiabel = false;
      this.$refs.video.pause();
    },
    async handlePreview(file, index) {
      if (file.type.includes("video")) {
        if (file.status == "success") {
          this.videoVisiabel = true;
          this.previewVideo = file.response.data;
        } else {
          this.$message.warn("请等待上传完成后预览");
        }
      } else if (file.type.indexOf("image") > -1) {
        this.previewVisible = true;
        this.previewImage = file.url;
      }
    },
    //获取图片base64地址
    toBase64(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader(); // 使用 FileReader 来获取图片路径及预览效果
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          resolve(e.target.result); // base 64 图片地址形成预览
        };
      });
    },
    async handleChange() {
      // 加入图片
      var event = event || window.event;
      var file = event.target.files;
      var leng = file.length;
      const length = this.fileList.length + leng;
      if (this.limit && length > this.limit) {
        this.$message.error(`只能上传最多${this.limit}张图片`);
        return;
      }
      for (var i = 0; i < leng; i++) {
        const img = new CreateFile({
          fileName: file[i].name,
          type: file[i].type,
        });
        this.fileList.push(img);
        if (file[i].type.includes("video")) {
          img.url = "../video.png";
        } else if (file[i].type.includes("image")) {
          this.toBase64(file[i]).then((res) => {
            img.url = res;
          });
        } else {
          // img.url = "../file.png";
        }
        if (!this.beforeUpload(file[i])) {
          //格式不正确或文件大小超限
          img.status = "error";
          continue;
        }
        const newFormData = new FormData();
        newFormData.append("file", file[i]);

        const handleProgress = (progress) => {
          const { loaded, total } = progress;
          img.press = Number(((loaded / total) * 100).toFixed(2));
        };
        upload(
          {
            file: newFormData,
          },
          handleProgress
        )
          .then((res) => {
            img.url = res.url;
            img.id = res.id;
            img.action = 1;
            img.status = "success";
            this.change();
          })
          .catch((err) => {
            img.status = "fail";
          });
      }
      event.target.value = "";
      this.change();
    },
    //获取附件信息
    getFile(id) {
      return new Promise((resolve, reject) => {
        FileInfo({ id })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    //点击上传按钮
    handleClick() {
      this.$refs.fileRef.dispatchEvent(new MouseEvent("click"));
    },
    //删除
    handleRemove(item, index) {
      //如果已经上传成功的文件，保存到要删除的数组中，后续从oss 删除
      if (item.status == "success") {
        this.deleteIds.push(item);
      } else if (item.status == "loading") {
        //删除正在上传的图片，则执行取消上传操作
        item.source.cancel();
      }
      this.fileList.splice(index, 1);
      this.change();
    },
    //删除oss 资源
    removeOssFiles() {
      if (this.deleteIds.length <= 0) return;
      const ids = this.deleteIds.map((item) => item.id);
      patchDeleteFile({ ids: ids.join(",") });
    },
    //删除新上传的文件
    removeNewFiles() {
      const ids = this.fileList
        .filter((item) => item.action && item.action == 1)
        .map((item) => item.id);
      const deleteIds = this.deleteIds
        .filter((item) => item.action && item.action == 1)
        .map((item) => item.id);
      if (ids.length) {
        patchDeleteFile({ ids: [...ids, ...deleteIds].join(",") });
      }
    },
    //上传之前检查
    beforeUpload(file) {
      const fileType = file.type.split("/")[0];
      const fileSize = file.size / 1024 / 1024;
      let fileTypeTips = "";
      let fileTypeStatus = true;
      let overSize = false;
      if (!this.accept.includes(fileType)) {
        if (this.accept.includes("video")) {
          fileTypeTips = "只能上传视频文件!";
          fileTypeStatus = false;
          this.$message.error(fileTypeTips);
        } else if (this.accept.includes("image")) {
          fileTypeTips = "只能上传jpg、png图片文件!";
          fileTypeStatus = false;
          this.$message.error(fileTypeTips);
        } else if (
          this.accept.includes("apk") &&
          !file.type.includes("package")
        ) {
          fileTypeTips = "只能上传APK文件!";
          fileTypeStatus = false;
          this.$message.error(fileTypeTips);
        }
      } else {
        if (this.accept.includes("video") && file.type != "video/mp4") {
          fileTypeTips = "只能上传MP4格式的视频文件!";
          fileTypeStatus = false;
          this.$message.error(fileTypeTips);
        }
        if (
          this.accept.includes("image") &&
          file.type != "image/jpeg" &&
          file.type != "image/jpg" &&
          file.type != "image/png"
        ) {
          fileTypeTips = "只能上传jpg、png图片文件!";
          fileTypeStatus = false;
          this.$message.error(fileTypeTips);
        }
      }
      if (this.size > 0 && fileSize > this.size) {
        this.$message.error(`文件不能超过${this.size}MB!`);
        overSize = true;
      }
      const flag = fileTypeStatus && !overSize;
      return flag;
    },
    change() {
      // const filesIds = this.fileList.map((item) => item.id);
      console.log(this.fileList)
      const files = this.fileList.map((item) => {
        return { url: item.url,status:item.status };
      });
      if (this.multiple) {
        this.$emit("change", files);
      } else {
        this.$emit("change", files[0]);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.uploadBtn {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  font-size: 30px;
  color: #999;
  margin-bottom: 10px;
  margin-right: 10px;
  text-align: center;
  background-color: #f9f9f9;
  border: 1px dashed #999;
  overflow: hidden;
  input[type="file"] {
    display: none;
  }
  .upload_btn_content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ant-upload-text {
    font-size: 14px;
    line-height: 1.2;
  }
}
.list {
  .item {
    display: inline-block;
    width: 100px;
    height: 100px;
    position: relative;
    margin-bottom: 10px;
    margin-right: 10px;
    overflow: hidden;
    border: 1px dashed #999;
    .status {
      width: 100px;
      line-height: 20px;
      text-align: center;
      color: #fff;
      font-size: 12px;
      background: #1890ff;
      transform: rotate(45deg);
      transform-origin: center;
      position: absolute;
      left: 30px;
      top: 10px;
    }
    .statusErr {
      background: #ff4d4f;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .btnsMark {
      opacity: 0;
      transition: all 0.3s;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      color: #f9f9f9;
      font-size: 16px;
      line-height: 100px;
      z-index: 2;
      text-align: center;
    }
    &:hover {
      .btnsMark {
        opacity: 1;
      }
    }
  }
}
.precessBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding-left: 10px;
  padding-top: 10px;
  box-sizing: border-box;
}
</style>

<style scope>
.ant-carousel .custom-slick-arrow {
  width: 35px !important;
  height: 35px;
  font-size: 35px;
  border-radius: 50%;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11) !important;
  opacity: 0.8 !important;
}
.custom-slick-arrow:before {
  display: none;
}
.custom-slick-arrow:hover {
  opacity: 0.5;
}
</style>
