<template>
  <div id="app" ref="app">
    <router-view v-if="isReload" />
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return { isReload: true };
  },
  provide() {
    return {
      reload: this.reload
    };
  },
  mounted() {
    console.log(window.getComputedStyle(this.$refs.app).getPropertyValue("height"));
    console.log(window)
  },
  created() {},
  methods: {
    async reload() {
      this.isReload = false;
      await this.$nextTick();
      this.isReload = true;
    }
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}
.dialog-footer{
  text-align: center;
}
</style>
