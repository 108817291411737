import router from "./router";
import store from "./store";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "@/utils/storage"; // get token from cookie
import getPageTitle from "@/utils/get-page-title";
import { needPermission } from "./config/setting";
NProgress.configure({
  showSpinner: false
}); // NProgress Configuration
const whiteList = ["/login", "/404", "/500", "/redirect"]; // no redirect whitelist     路由白名单，不检测token
const loginRoutePath = "/login";
const defaultRoutePath = "/";
router.beforeEach(async (to, from, next) => {
  console.log(to)
  console.log(from)
  if (to.path === from.path) {
    // 当前路由与目标路由相同，避免重复导航
    next(false); // 或者你可以选择重定向到其他路由
  }
  router.fromPath = from.fullPath;
  NProgress.start();
  document.title = getPageTitle(to.meta.title);
  const Token = store.getters.token || getToken();
  const roles = store.getters.roles;
  if (!needPermission) {
    store.dispatch("add_route", to);
    store.dispatch("change_alive_route", to.fullPath);
    next();
    return
  }

  if (Token) {
    if (to.path === loginRoutePath) {
      next({
        path: defaultRoutePath
      });
      NProgress.done();
    } else {
      if (roles.length === 0) {
        store
          .dispatch("GetInfo")
          .then((res) => {
            store.dispatch("GenerateRoutes").then(() => {
              console.log(store.getters)
              // 根据roles权限生成可访问的路由表
              store.getters.addRouters.forEach((r) => {
                router.addRoute(r);
              });
              // 请求带有 redirect 重定向时，登录自动重定向到该地址
              const redirect = decodeURIComponent(
                from.query.redirect || to.path
              );
              if (to.path === redirect) {
                // set the replace: true so the navigation will not leave a history record
                next({
                  ...to,
                  replace: true
                });
              } else {
                // 跳转到目的路由
                next({
                  path: redirect
                });
              }
            });
          })
          .catch((err) => {
            store.dispatch("Logout").then(() => {
              // next({
              //   path: loginRoutePath,
              //   query: {
              //     // redirect: to.fullPath
              //   }
              // });
            });
          });
      } else {
        store.dispatch("add_route", to);
        store.dispatch("change_alive_route", to.fullPath);
        next();
        NProgress.done();
      }
    }
  } else {
    if (whiteList.includes(to.path)) {
      console.log('白名单')
      //跳转链接为白名单
      next();
      // store.dispatch('add_route', to)
      // store.dispatch('change_alive_route', to.fullPath)
      NProgress.done();
    } else {
      console.log('黑名单')
      next({
        path: loginRoutePath
      });
      NProgress.done();
    }
  }
});
router.afterEach(() => {
  NProgress.done(); // finish progress bar
});
