import keepAlive from "@/components/keepAlive";
import layout from "../layout/index.vue";

// 基础路由
const baseRoutes = [
  {
    path: "/",
    name: "/",
    component: layout,
    meta: {
      title: "首页"
    },
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: {
          icon: "home",
          title: "工作台"
        },
        component: () => import("@/views/dashboard/index.vue")
      },
      {
        path: "/table",
        name: "Table",
        meta: {
          icon: "el-icon-tickets",
          title: "表格",
          hidden: true
        },
        component: () => import("@/views/table/index.vue")
      },
      {
        path: "/form",
        name: "Form",
        meta: {
          icon: "el-icon-edit-outline",
          title: "表单",
          hidden: false,
          keepAlive: true
        },
        component: () => import("@/layout/pageView.vue"),
        children: [
          {
            path: "/form",
            name: "Form",
            meta: {
              icon: "el-icon-edit-outline",
              title: "普通表单",
              keepAlive: true
            },
            component: () => import("@/views/form/index.vue")
          },
          {
            path: "/form-goods",
            name: "FormGoods",
            meta: {
              icon: "el-icon-edit-outline",
              title: "商品表单",
              keepAlive: true
            },
            component: () => import("@/views/form/form-goods.vue")
          }
        ]
      },
      
    ]
  },
  {
    path: "/user",
    name: "user",
    redirect: "/login",
    meta: {
      icon: "user",
      title: "用户",
      hidden: true
    },
    component: () => import("@/layout/pageView"),
    children: [
      {
        path: "/login",
        name: "userLogin",
        meta: {
          title: "登录"
        },
        component: () => import("@/views/user/login")
      },
      {
        path: "/firstlogin",
        name: "firstlogin",
        meta: {
          title: "首次登录",
          hidden: true
        },
        component: () => import("@/views/user/firstLogin")
      },
      {
        path: "/updatePassword",
        name: "updatePassword",
        meta: {
          title: "修改密码",
          hidden: true
        },
        component: () => import("@/views/user/updatePassword")
      }
    ]
  }
];
//后台菜单配置
const selectComponents = [
  {
    name: "主容器",
    path: "",
    component: "layout",
    code: "container"
  },
  {
    name: "活动分类",
    path: "/classify/list",
    component: "ClassifyList",
    redirect: "",
    code: "classify:list"
  },
  {
    name: "分类",
    path: "/classify/create",
    component: "ClassifyCreate",
    redirect: "",
    code: "classify:create"
  }, {
    name: "活动奖项",
    path: "/awards/list",
    component: "AwardsList",
    redirect: "",
    code: "awards:list"
  },
  {
    name: "奖项",
    path: "/awards/create",
    component: "AwardsCreate",
    redirect: "",
    code: "awards:create"
  },
  {
    name: "活动列表",
    path: "/activity/list",
    component: "ActivityList",
    redirect: "",
    code: "activity:list"
  },
  {
    name: "活动",
    path: "/activity/create",
    component: "ActivityCreate",
    redirect: "",
    code: "activity:create"
  }, {
    name: "活动详情",
    path: "/activity/info",
    component: "ActivityInfo",
    redirect: "",
    code: "activity:info"
  }, {
    name: "社群列表",
    path: "/group/list",
    component: "GroupList",
    redirect: "",
    code: "group:list"
  },{
    name: "进群申请",
    path: "/group/apply/list",
    component: "GroupApplyList",
    redirect: "",
    code: "group:apply:list"
  },
  {
    name: "用户管理",
    path: "/custom/list",
    component: "CustomList",
    redirect: "",
    code: "custom:list"
  }, {
    name: "用户",
    path: "/custom/create",
    component: "CustomCreate",
    redirect: "",
    code: "custom:create"
  }, {
    name: "用户详情",
    path: "/custom/createinfo",
    component: "CustomInfo",
    redirect: "",
    code: "custom:info"
  },
  {
    name: "标签管理",
    path: "/tags/list",
    component: "TagsList",
    redirect: "",
    code: "tags:list"
  },
  {
    name: "资讯管理",
    path: "/article/list",
    component: "ArticleList",
    redirect: "",
    code: "article:list"
  },
  {
    name: "资讯",
    path: "/article/create",
    component: "ArticleCreate",
    redirect: "",
    code: "article:create"
  }, {
    name: "资讯详情",
    path: "/article/info",
    component: "ArticleInfo",
    redirect: "",
    code: "article:info"
  }, {
    name: "常见问题管理",
    path: "/question/list",
    component: "QuestionList",
    redirect: "",
    code: "question:list"
  },
  {
    name: "常见问题",
    path: "/question/create",
    component: "QuestionCreate",
    redirect: "",
    code: "question:create"
  },{
    name: "广告位管理",
    path: "/advert/list",
    component: "AdvertList",
    redirect: "",
    code: "advert:list"
  },
  {
    name: "广告位",
    path: "/advert/create",
    component: "AdvertCreate",
    redirect: "",
    code: "advert:create"
  },
  {
    name: "菜单管理",
    path: "/system/meun/list",
    component: "SystemMeun",
    redirect: "",
    code: "system:meun"
  },
  {
    name: "角色管理",
    path: "/system/roles/list",
    component: "Roles",
    redirect: "",
    code: "roles"
  },
  {
    name: "用户管理",
    path: "/system/user/list",
    component: "SystemUser",
    redirect: "",
    code: "user"
  },
  {
    name: "组织管理",
    path: "/partment",
    component: "Partment",
    redirect: "",
    code: "partment"
  },
  {
    name: "组织",
    path: "/partment/edit/:type",
    component: "PartmentEdit",
    redirect: "",
    code: "partmentEdit"
  },
  {
    name: "组织详情",
    path: "/partment/info",
    component: "PartmentInfo",
    redirect: "",
    code: "PartmentInfo"
  },
  {
    name: "字典管理",
    path: "/system/dictionary/list",
    component: "SystemDictionary",
    redirect: "",
    code: "system:dictionary"
  },
  {
    name: "字典数据",
    path: "/system/dictionary/data",
    component: "SystemDictionaryData",
    redirect: "",
    code: "dictionaryData"
  },
  {
    name: "平台设置",
    path: "/system/setting",
    component: "SystemSetting",
    redirect: "",
    code: "system:setting"
  }
];
export { baseRoutes, selectComponents };
