<script>
export default {
  name: "MenuItem",
  functional: true,
  props: {
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    }
  },
  render(h, context) {
    const { icon, title } = context.props;
    const vnodes = [];
    if (icon) {
      if (icon.includes("el-icon")) {
        vnodes.push(<i class={[icon, "sub-el-icon"]} />);
      } else {
        vnodes.push(<svg-icon icon-class={icon}  class="sub-el-icon"/>);
      }
    }else{
      vnodes.push(<span icon-class={icon}  class="sub-el-icon"></span>);
    }

    if (title) {
      vnodes.push(
        <span slot="title" class="meunName">
          {title}
        </span>
      );
    }
    return vnodes;
  }
};
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  width: 24px;
  height: 18px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}
.meunName{
  vertical-align: middle;
}
</style>
