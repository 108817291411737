<template>
  <div class="app-wrapper">
    <div class="app-aside" :style="{ width: sidebar.width }">
      <Aside :collapse="!sidebar.opened" />
    </div>
    <div
      class="app-container"
      :style="{
        width: `calc(100% - ${sidebar.width})`,
        marginLeft: sidebar.width
      }"
    >
      <TopBar />
      <NavTab />
      <!-- <Breadcrumb /> -->
      <AppMain />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Aside from "./Aside/index.vue";
import TopBar from "./topBar/index.vue";
import NavTab from "./NavTab/index.vue";
import AppMain from './AppMain'
// import Breadcrumb from './Breadcrumb'
export default {
  name: "Layout",
  data() {
    return {};
  },
  components: { Aside, TopBar ,AppMain,NavTab,
    // Breadcrumb
  },
  computed: {
    ...mapGetters(["sidebar"])
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.app-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
}
.app-aside {
  position: fixed;
  height: 100%;
  background: $menuBg;
  transition: width 0.3s;
}
.app-container {
  position: relative;
  height: 100%;
}
</style>
