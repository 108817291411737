var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',_vm._b({staticClass:"el-dropdown-menu el-dropdown-menu--default",staticStyle:{"outline":"none","background-color":"#fff"}},'ul',_vm.$attrs,false),[_c('li',{staticClass:"el-dropdown-menu__item",on:{"click":function($event){$event.stopPropagation();return _vm.refresh.apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-refresh"}),_vm._v("重新加载 ")]),_c('li',{staticClass:"el-dropdown-menu__item",class:{
      'is-disabled': _vm.isOnly || _vm.activeIndex == 0
    },on:{"click":function($event){$event.stopPropagation();return _vm.onCloseActive.apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-close"}),_vm._v(" 关闭当前 ")]),_c('li',{staticClass:"el-dropdown-menu__item",class:{
      'is-disabled': _vm.activeIndex < 2
    },on:{"click":function($event){$event.stopPropagation();return _vm.onCloseLeft.apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-back"}),_vm._v(" 关闭左侧 ")]),_c('li',{staticClass:"el-dropdown-menu__item",class:{
      'is-disabled': _vm.activeIndex == _vm.tabLength - 1
    },on:{"click":function($event){$event.stopPropagation();return _vm.onCloseRight.apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-right"}),_vm._v(" 关闭右侧 ")]),_c('li',{staticClass:"el-dropdown-menu__item",class:{
      'is-disabled': _vm.isOnly || _vm.activeIndex < 2
    },on:{"click":function($event){$event.stopPropagation();return _vm.onCloseOther.apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-rank"}),_vm._v(" 关闭其他 ")]),_c('li',{staticClass:"el-dropdown-menu__item",class:{
      'is-disabled': _vm.isOnly
    },on:{"click":function($event){$event.stopPropagation();return _vm.onCloseAll.apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-minus"}),_vm._v(" 关闭全部 ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }