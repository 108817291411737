import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./plugins/element.js";
import "./directives/action"; //全局自定义指令
import "./directives/loadmore"; //全局自定义指令
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/style/element-variables.scss";
import "./icons/index";
import "./permission";
import "./utils/filter";
import {order_types,in_out_bound_types} from "@/config/mutation-types"
import shTable from "@/components/sh-table";
import shSearchBar from "@/components/sh-search-bar.vue";
import shPagination from "@/components/sh-pagination.vue";
import shNumberInput from "@/components/shNumberInput.vue";
import shSelect from "@/components/sh-remote-select.vue";
import Tinymce from "@/components/Tinymce";
import { goBack, debounce, throttle} from "@/utils/util";
import { getDicDataByCode, filterDicLabel } from "@/utils/diction";
Vue.component("shSearchBar", shSearchBar);
Vue.component("shTable", shTable);
Vue.component("shPagination", shPagination);
Vue.component("shNumberInput", shNumberInput);
Vue.component("shSelect", shSelect);
Vue.component('Tinymce', Tinymce);
Vue.config.productionTip = false;
Vue.prototype.$goBack = goBack;
Vue.prototype.$getDicDataByCode = getDicDataByCode;
Vue.prototype.$filterDicLabel = filterDicLabel
Vue.prototype.$debounce = debounce;
Vue.prototype.$throttle = throttle;
Vue.prototype.$deepCopy = function (obj){return JSON.parse(JSON.stringify(obj))}
Vue.prototype.$orderType=order_types
Vue.prototype.$InOutBoundType=in_out_bound_types
import {
  VueJsonp
} from 'vue-jsonp'
Vue.use(VueJsonp) //解决请求腾讯地图服务Api时的跨域问题
if(process.env.NODE_ENV==="production"){
  console.log = () => {}
}
new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount("#app");
