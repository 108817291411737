import request from '@/utils/request'
const api = {
  meun: '/system/menu',
  meunList: '/menus/list/',

}
// 新增菜单
export function addMeun(parameter) {
  return request({
    url: api.meun,
    method: 'post',
    data: parameter
  })
}
// 删除菜单
export function delMeun(parameter) {
  return request({
    url: api.meun,
    method: 'delete',
    data: parameter
  })
}
// 编辑菜单
export function editMeun(parameter) {
  return request({
    url: api.meun ,
    method: 'put',
    data: parameter
  })
}
// 菜单详情
export function infoMeun(parameter) {
  return request({
    url: api.meun + parameter.id + '/',
    method: 'get',
    data: parameter
  })
}
// 菜单分页列表
export function meunPageList(parameter) {
  return request({
    url: api.meun,
    method: 'get',
    params: parameter
  })
}

// 菜单不分页列表
export function meunList(parameter) {
  return request({
    url: api.meun,
    method: 'get',
    params: parameter
  })
}

