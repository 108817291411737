import Vue from 'vue'
import moment from 'moment'
// 无参数时的展示
Vue.filter('notText', function (text) {
    return text || '无'
})


Vue.filter('formatTime', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
    return moment(dataStr).format(pattern)
})



Vue.filter('findNameById', function ( value,array, key = 'id') {
    const tempObj = array.find(item => item[key] == value)
    return tempObj ? (tempObj.name || tempObj.text) : ''
})