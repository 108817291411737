<template>
  <div class="menuList">
    <el-menu
      :default-active="activeMenu"
      :collapse="collapse"
      background-color="#2A354E"
      text-color="#f1f1f1"
      active-text-color="#ffd04b"
      :unique-opened="false"
      :collapse-transition="false"
      mode="vertical"
    >
      <meunItem
        v-for="route in routes"
        :key="route.path"
        :item="route"
        :base-path="route.path"
      />
    </el-menu>
  </div>
</template>

<script>
import meunItem from "./meunItem.vue";
import variables from "@/assets/style/variables.scss";
export default {
  name: "AppMeun",
  props: {
    collapse: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    console.log(variables)
  },
  computed: {
    routes() {
      const meun=this.$router.options.routes.find(item=>item.path==='/')
      return (meun&& meun.children)|| [];
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  components: { meunItem },
  data() {
    return {
      variables:variables
    }
  },
};
</script>


<style lang="scss" scoped>
.menuList {
  height: calc(100vh - 50px);
  text-align: left;
  overflow-y: auto;
  &::-webkit-scrollbar{
    width: 10px;
  }
}
</style>