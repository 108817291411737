import { pinyin } from "pinyin-pro";
export function timeFix(username) {
  const time = new Date();
  const hour = time.getHours();
  let welcome =
    hour < 9
      ? "早上好"
      : hour <= 11
      ? "上午好"
      : hour <= 13
      ? "中午好"
      : hour < 20
      ? "下午好"
      : "晚上好";
  return welcome + "," + username;
}

export function welcome() {
  const arr = [
    "休息一会儿吧",
    "准备吃什么呢?",
    "要不要打一把 DOTA",
    "我猜你可能累了"
  ];
  const index = Math.floor(Math.random() * arr.length);
  return arr[index];
}
//获取页面参数
export function getUrlParams(path) {
  const pathArr = path.split("?");
  if (pathArr.length > 1) {
    const params = {};
    const paramArr = pathArr[1].split("&");
    paramArr.map((item) => {
      const paramValue = item.split("=");
      if (paramValue.length > 1) {
        params[paramValue[0]] = paramValue[1];
      }
    });
    return params;
  } else {
    return {};
  }
}
/**
 * 给对象多级属性赋值
 * const obj={
 *    key:'11',
 *    book:{
 *      name:'一本书'
 *    }
 * }
 * const field='book.name'
 * const newValue='修改后的书'
 * SetObjValue(obj,field,newValue)
 *
 * console.log(obj.book.name)
 * //修改后的书
 */
export function SetObjValue(object, field, val) {
  const newObj = object;
  var arr = field.split(".");
  var str = "newObj";
  for (var i = 0; i < arr.length; i++) {
    str += "." + arr[i];
    if (eval(str) == undefined) {
      eval(str + "={}");
    }
  }
  eval(str + "=val");
}
/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent("HTMLEvents");
  event.initEvent("resize", true, true);
  event.eventType = "message";
  window.dispatchEvent(event);
}

export function handleScrollHeader(callback) {
  let timer = 0;

  let beforeScrollTop = window.pageYOffset;
  callback = callback || function () {};
  window.addEventListener(
    "scroll",
    (event) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        let direction = "up";
        const afterScrollTop = window.pageYOffset;
        const delta = afterScrollTop - beforeScrollTop;
        if (delta === 0) {
          return false;
        }
        direction = delta > 0 ? "down" : "up";
        callback(direction);
        beforeScrollTop = afterScrollTop;
      }, 50);
    },
    false
  );
}

export function isIE() {
  const bw = window.navigator.userAgent;
  const compare = (s) => bw.indexOf(s) >= 0;
  const ie11 = (() => "ActiveXObject" in window)();
  return compare("MSIE") || ie11;
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = "", timeout = 1500) {
  if (id === "") {
    return;
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id));
  }, timeout);
}
export function scorePassword(pass) {
  let score = 0;
  if (!pass) {
    return score;
  }
  // award every unique letter until 5 repetitions
  const letters = {};
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass)
  };

  let variationCount = 0;
  for (var check in variations) {
    variationCount += variations[check] === true ? 1 : 0;
  }
  score += (variationCount - 1) * 10;

  return parseInt(score);
}

/**
 * @description 金额保留小数点前两位
 * @param {*} value
 */
export function NumberFormats(value) {
  const tempVal = parseFloat(value).toFixed(3);
  const realVal = tempVal.substring(0, tempVal.length - 1);
  return realVal;
}

/**
 * @description 树形转数组
 * @param {*} tree
 * @param {*} key 要向下遍历的 key
 */
export function treeToList(tree, key = "children") {
  let arr = [];
  tree.map((item) => {
    if (item[key] && item[key].length) {
      arr = arr.concat(treeToList(item[key]));
    } else {
      arr.push(item);
    }
  });
  return arr;
}
export const listTreeCheck = (list, isArr) => {
  const parentArr = [];
  list.forEach((item) => {
    isArr.forEach((is, index) => {
      if (item.id === is) {
        if (item.children !== null) {
          const isF = listTreeCheck1(item.children, isArr, item, [item.id]);
          isF.forEach((isf) => {
            parentArr.push(isf);
          });
        }
      }
    });
  });
  const arr = deduplicate(isArr, parentArr).filter((s) => {
    return s;
  });
  return {
    isArr: arr,
    halfCheckedKeys: parentArr
  };
};
export const listTreeCheck1 = (children, isArr, parent, is) => {
  const len = children.length;
  let len1 = 0;
  children.forEach((item) => {
    isArr.forEach((isItem) => {
      if (item.id === isItem) {
        len1 = len1 + 1;
      }
    });
    if (item.children !== null) {
      listTreeCheck1(item.children, isArr, item, is.push(item.id));
    }
  });
  if (len > len1) {
    return is;
  }
  return is;
};
export const deduplicate = (arr1, arr2) => {
  arr1.forEach((a1, index) => {
    arr2.forEach((a2) => {
      if (a1 === a2) {
        delete arr1[index];
      }
    });
  });
  return arr1;
};

export const formatDate = (stamp) => {
  const y = new Date(stamp).getFullYear();
  const m = new Date(stamp).getMonth() + 1;
  const d = new Date(stamp).getDate();
  const h = new Date(stamp).getHours();
  const mi = new Date(stamp).getMinutes();
  const s = new Date(stamp).getSeconds();
  return `${y}-${m > 9 ? m : "0" + m}-${d > 9 ? d : "0" + d} ${
    h > 9 ? h : "0" + h
  }:${mi > 9 ? mi : "0" + mi}:${s > 9 ? s : "0" + s}`;
};

//设置所有不可选
export const setAllDisabled = (list) => {
  list.forEach((item) => {
    item.disabled = true;
    if (item.children) {
      item.children = setAllDisabled(item.children);
    }
  });
  return list;
};

export const findActiveOrganization = (id, list) => {
  for (const index in list) {
    if (list[index].identifier == id) {
      list[index].disabled = true;
      if (list[index].children) {
        list[index].children = setAllDisabled(list[index].children);
      }
      return list;
    } else if (list[index].children) {
      findActiveOrganization(id, list[index].children);
    }
  }
  return list;
};

//删除children 长度为0 的children 字段
export function clearEmptyChildren(list) {
  let arr = [];
  list.map((item) => {
    if (item.children && item.children.length > 0) {
      item.children = clearEmptyChildren(item.children);
    } else {
      delete item.children;
    }
    arr.push(item);
  });
  return arr;
}

export function getId() {
  return (
    "field" +
    (Math.floor(Math.random() * (99999 - 10000)) + 10000).toString() +
    new Date().getTime().toString().substring(5)
  );
}

/**
 * @description 导出csv
 * @param {String} textName 导出的文件名
 * @param {*} data 流数据
 */
export const download = (textName, data, type = ".xlsx") => {
  const blob = new Blob([data], {
    type: "application/octet-stream"
  });
  const downloadElement = document.createElement("a");
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;

  downloadElement.download = textName + type;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
};

//根据文字生成图片
export function createImgByName(name, size = [100, 100]) {
  const colors = [
    { letter: ["A", "B"], color: "#31bc9f" },
    { letter: ["C", "D"], color: "#4a94db" },
    { letter: ["E", "F"], color: "#9b5fb6" },
    { letter: ["G", "H"], color: "#16a085" },
    { letter: ["I", "J"], color: "#27ae60" },
    { letter: ["K", "L"], color: "#8e44ad" },
    { letter: ["M", "N"], color: "#f1cb1e" },
    { letter: ["O", "P"], color: "#e6761b" },
    { letter: ["Q", "R"], color: "#00bcd4" },
    { letter: ["S", "T"], color: "#f39c12" },
    { letter: ["U", "V"], color: "#d35400" },
    { letter: ["W", "X"], color: "#51da9c" },
    { letter: ["Y", "Z"], color: "#8064d5" }
  ];
  let text = "";
  const nameSplit = String(name).split("");
  const pinYinCode = pinyin(nameSplit[0], {
    pattern: "first",
    toneType: "none",
    type: "array"
  })
    .join("")
    .toUpperCase();
  const tempColorObj = colors.find((item) => item.letter.includes(pinYinCode));
  const color = tempColorObj ? tempColorObj.color : "#d35400";
  if (nameSplit.length == 1) {
    text = nameSplit[0] ? nameSplit[0].charAt(0) : "?";
  } else {
    text =
      nameSplit[nameSplit.length - 2].charAt(0) +
      nameSplit[nameSplit.length - 1].charAt(0);
  }
  let cvs = document.createElement("canvas");
  cvs.setAttribute("width", size[0]);
  cvs.setAttribute("height", size[1]);
  let ctx = cvs.getContext("2d");
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, size[0], size[1]);
  ctx.fillStyle = "rgb(255,255,255)";
  ctx.font = size[0] * 0.3 + "px Arial";
  ctx.textBaseline = "middle";
  ctx.textAlign = "center";
  ctx.fillText(text, size[0] / 2, size[1] / 2);
  return cvs.toDataURL("image/jpeg", 1);
}

//返回前 一个页面，并关闭tab标签
export function goBack(v) {
  v.$store.dispatch("remove_route", v.$route.fullPath);
  v.$router.replace({ path: v.$router.fromPath });
}

export function formateDate(datetime, type="YYYY-MM-DD") {
  var year = datetime.getFullYear(),
    month = ("0" + (datetime.getMonth() + 1)).slice(-2),
    date = ("0" + datetime.getDate()).slice(-2),
    hour = ("0" + datetime.getHours()).slice(-2),
    minute = ("0" + datetime.getMinutes()).slice(-2),
    second = ("0" + datetime.getSeconds()).slice(-2);
  let result = "";
  if (type === "YYYY-MM-DD") {
    result = year + "-" + month + "-" + date;
  } else if (type === "YYYY-MM") {
    result = year + "-" + month;
  }
  return result;
}

//防抖函数
export function debounce(fn, wait = 300) {
  let timer;
  return function () {
    let _this = this;
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      fn.apply(_this, args);
      clearTimeout(timer);
      timer = null;
    }, wait);
  };
}
//节流函数
export function throttle(fn, wait = 300) {
  let time = 0;
  return function () {
    let _this = this;
    let args = arguments;
    let now = Date.now();
    if (now - time > wait) {
      fn.apply(_this, args);
      time = now;
    }
  };
}
