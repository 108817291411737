const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  userinfo: state => state.user.userInfo,
  firstLogin: state => state.user.firstLogin,
  token: state => state.user.token,
  loginType: state => state.user.loginType,
  roles: state => state.user.roles,
  keepComponents:state=>state.navtab.keepComponents,
  dictions:state=>state.system.dictions,
  routes: state => state.navtab.routes,
  pageType:state=>state.navtab.pageType,
  addRouters: state => state.permission.addRouters,
  aliveRoute: state => state.navtab.aliveRoute,
}
export default getters