<template>
  <el-form :model="queryParams" ref="queryParams" size="small" :inline="true">
    <slot></slot>
    <el-form-item v-if="show">
      <el-button
        type="primary"
        icon="el-icon-search"
        size="mini"
        @click="onSearch"
        >搜索</el-button
      >
      <el-button icon="el-icon-refresh" size="mini" @click="onReset"
        >重置</el-button
      >
      <slot name="buttonSlot"> </slot>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default() {
        return true;
      },
    },
    onSearch: {
      type: Function,
      default() {
        return null;
      },
    },
    onReset: {
      type: Function,
      default() {
        return null;
      },
    },
    queryParams: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    resetFields() {
      this.$refs.queryParams.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>  
