//处理菜单列表为树形
export function menusToTree(list, root = 0) {
  const arr = []
  list.forEach((item) => {
    // 2.首次传入null  判断list的parentId是否为空 如果为空就是一级节点
    if (item.parentId === root) {
      // 找到之后就要去找item下面有没有子节点  以 item.identifier 作为 父 id, 接着往下找
      const children = menusToTree(list, item.id)
      if (children.length > 0) {
        // 如果children的长度大于0,说明找到了子节点
        item.children = children
      }
      // 将item项, 追加到arr数组中
      arr.push(item)
    }
  })
  arr.sort((a,b)=>a.sort-b.sort)
  return arr
}


//获取树形的最下级IDS
export function getTreeLastIds(list) {
  let ids = [];
  list.map((item) => {
    if (item.children && item.children.length > 0) {
      ids = ids.concat(getTreeLastIds(item.children));
    } else {
      ids.push(item.identifier)
    }
  });
  return ids
}
//获取树形的所有IDS
export function getTreeAllIds(obj) {
  let ids = [];
  ids.push(obj.identifier)
  if (obj.children && obj.children.length) {
    obj.children.map((item) => {
      ids = ids.concat(getTreeAllIds(item))
    });
  }
  return ids
}

//删除children 长度为0 的children 字段
export function clearEmptyChildren(list) {
  let arr = [];
  list.map((item) => {
    if (item.children && item.children.length > 0) {
      item.children = clearEmptyChildren(item.children);
    } else {
      delete item.children
    }
    arr.push(item)
  });
  return arr
}