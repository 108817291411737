import request from "@/utils/request";
const api = {
  dicts: "/system/dictionary",
  // dictData: "/system/dictionary/datas"
};
// 字典类型分页列表
export function dictPageList(data) {
  return request({
    url: api.dicts,
    method: "GET",
    header: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    params: data
  });
}
// 字典类型不分页列表
export function dictList(data) {
  return request({
    url: `${api.dicts}/list`,
    method: "GET",
    header: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    params: data
  });
}
// 新增字典类型
export function addDict(data) {
  return request({
    url: api.dicts,
    method: "POST",
    header: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: data
  });
}
// 修改字典类型
export function updateDict(data) {
  return request({
    url: `${api.dicts}`,
    method: "put",
    header: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: data
  });
}
// 删除字典类型
export function deleteDict(data) {
  return request({
    url: `${api.dicts}`,
    method: "delete",
    header: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: data
  });
}
// 字典类型状态改变
export function setStatus(data) {
  return request({
    url: `${api.dicts}/set_status`,
    method: "post",
    header: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: data
  });
}

// // 字典数据类型列表（code）
// export function dictDataListByCode(data) {
//   const { code } = data;
//   return request({
//     url: `${api.dictData}`,
//     method: "GET",
//     params: data
//   });
// }
// // 字典数据类型列表
// export function dictDataList(data) {
//   const { dictId } = data;
//   return request({
//     url: `${api.dicts}${dictId}/datas/`,
//     method: "GET",
//     params: data
//   });
// }
// // 新增字典数据
// export function addDictData(data) {
//   const { dictId, identifier } = data;
//   return request({
//     url: `${api.dicts}${dictId}/datas/${identifier}`,
//     method: "POST",
//     data: data
//   });
// }
// // 修改字典数据
// export function updateDictData(data) {
//   const { dictId, identifier } = data;
//   return request({
//     url: `${api.dicts}${dictId}/datas/${identifier}`,
//     method: "put",
//     data: data
//   });
// }
// // // 删除字典数据
// export function deleteDictData(data) {
//   const {dictId,identifier}=data
//   return request({
//     url:`${api.dictData}${dictId}/datas/${identifier}`,
//     method: 'delete',
//     data: data
//   })
// }

// //启用字典数据
// export function enableDictData(data) {
//   const { dictId, identifier } = data;
//   return request({
//     url: `${api.dicts}${dictId}/datas/${identifier}/actions/enable/`,
//     method: "PUT",
//     header: {
//       "Content-Type": "application/x-www-form-urlencoded"
//     },
//   });
// }
// // 禁字典类型数据
// export function disableDictData(data) {
//   const { dictId, identifier } = data;
//   return request({
//     url: `${api.dicts}${dictId}/datas/${identifier}/actions/disable/`,
//     method: "PUT",
//     header: {
//       "Content-Type": "application/x-www-form-urlencoded"
//     },
//   });
// }
