import Vue from "vue";
const loadmore = Vue.directive("loadmore", {
  inserted(el, binding) {
    el.addEventListener("scroll", function () {
      const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
      if (condition) {
        binding.value();
      }
    });
  }
});
export default loadmore;
