<template>
  <div class="navbar dflexCB">
    <div :class="sidebar.opened ? 'unfold' : 'fold'">
      <i
        class="el-icon-s-unfold"
        style="font-size: 26px"
        @click="toggleSideBar"
      />
    </div>
    <div class="dflexCB flex1 ml20">
      <div></div>
      <!-- <el-menu class="el-menu-demo" mode="horizontal">
        <el-menu-item v-for="(item, index) in topMenu" :key="index">
          <div class="dflexCCC cmain mr10" style="height: 100%">
            <svg-icon :icon-class="item.icon" fill="#0000ff" />
            <span class="menuName">{{ item.name }}</span>
          </div>
        </el-menu-item>
      </el-menu> -->
      <!-- <div class="dflexC">
        <el-input
          placeholder="请输入关键字"
          v-model="keywords"
          size="small"
          class="input-with-select fz14 mr20"
        >
        </el-input> -->
      <!-- <el-badge :value="12" class="mr20">
          <i class="el-icon-bell" />
        </el-badge> -->
      <el-dropdown>
        <span class="el-dropdown-link dflexC cfff">
          <div class="navItem dflexC">
            <img
              class="userHeadIcon"
              :src="userinfo && userinfo.avatar"
              alt=""
            />
            <p class="navItemText">
              {{ (userinfo && userinfo.nick_name) || (userinfo && userinfo.name) ||"" }}
            </p>
          </div>

          <i class="el-icon-arrow-down el-icon--right" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <div class="bgfff tac f14">
            <el-dropdown-item>
              <p @click="resetPas()" class="pl20 pr20">
                修改密码
              </p>
            </el-dropdown-item>
            <el-dropdown-item>
              <p @click="logout()" class="pl20 pr20">退出登录</p>
            </el-dropdown-item>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavBar",
  data() {
    return {
      form: {
        feedback: "" //意见反馈内容
      },
      keywords: "",
      topMenu: [
        { icon: "home", name: "首页", url: "/" },
        { icon: "program", name: "应用中心", url: "/" }
      ]
    };
  },
  computed: {
    ...mapGetters(["sidebar", "userinfo", "name"])
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      await this.$store.dispatch("Logout");
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`);
      this.$router.push(`/login`);
    },
    resetPas() {
      this.$router.push({ name: "updatePassword" });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.navbar {
  height: 50px;
  padding: 0 10px;
  overflow: hidden;
  position: relative;
  background: #fff;
  padding-right: 25px;
  // background: linear-gradient(90deg, #0052c1, #3d95ff);
  background: $menuBg;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: flex;
  color: #fff;
  justify-content: flex-end;
  align-items: center;
  .unfold {
    transform: rotate(180deg);
    transition: all 0.3s;
  }
  .fold {
    transform: rotate(0);
    transition: all 0.3s;
  }
  .navItem {
    margin-left: 30px;
  }
  .navItemIcon {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
  .navItemText {
    color: #fff;
    font-size: 14px;
  }
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }
  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }
    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;
      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }
        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
.userHeadIcon {
  width: 30px !important;
  height: 30px;
  margin-right: 10px;
  border-radius: 30px;
}
.menuName {
  line-height: 1;
  margin-top: 5px;
}
</style>
