import request from '@/utils/request'
const api = {
  roles: '/system/roles'
}

// 新增角色
export function addRoles(parameter) {
  return request({
    url: api.roles,
    method: 'post',
    data: parameter
  })
}
// 删除角色
export function deleteRoles(param) {
  return request({
    url: `${api.roles}`,
    method: 'delete',
    data: param
  })
}

// 编辑角色
export function updateRoles(param) {
  return request({
    url: `${api.roles}`,
    method: 'put',
    data: param
  })
}

// 角色详情
export function infoRoles(param) {
  return request({
    url: `${api.roles}`,
    method: 'get',
    data: param
  })
}
// 角色分页查询
export function rolesPageList(param) {
  return request({
    url: `${api.roles}`,
    method: 'get',
    params: param
  })
}

// 设置角色状态
export function setStatus(param) {
  return request({
    url: `${api.roles}/set_status`,
    method: 'post',
    data: param
  })
}
// 绑定角色功能权限
export function roleMenu(param) {
  return request({
    url: `${api.roles}/menus`,
    method: 'post',
    data: param
  })
}
// 查看角色功能权限
export function getRoleMenu(param) {
  return request({
    url: `${api.roles}/menus`,
    method: 'get',
    params: param
  })
}
//角色列表查询
export function roleList(param) {
  return request({
    url: `${api.roles}/list/`,
    method: 'get',
    params: param
  })
}