import { getUrlParams } from "@/utils/util";
function hasThisRoute(routes, route) {
  return routes.some((item) => {
    return (
      item.fullPath == route.fullPath && item.meta.title == route.meta.title
    );
  });
}
//不用保留到tabs的页面
const blackList = ["/updatePassword", "/redirect","/login"];
const state = {
  routes: [],
  aliveRoute: "",
  keepComponents: [],
  pageType: new Map([
    ["create", "新增"],
    ["edit", "编辑"],
    ["preview", "详情"]
  ])
};
const mutations = {
  SET_ROUTE(state, routes) {
    state.routes = routes;
  },
  ADD_ROUTE(state, route) {
    if (blackList.includes(route.path)) return;
    const keepAliveName = route.fullPath;
    if (route.meta.keepAlive && !state.keepComponents.includes(keepAliveName)) {
      state.keepComponents.push(keepAliveName);
    }
    if (!hasThisRoute(state.routes, route)) {
      state.routes.push(route);
    }
    console.log(state.keepComponents)
  },
  REMOVE_ROUTE(state, key) {
    const index = state.routes.findIndex((item) => item.fullPath === key);
    const keepAliveIndex = state.keepComponents.findIndex(
      (item) => item == key
    );
    if (keepAliveIndex > -1) {
      state.keepComponents.splice(keepAliveIndex, 1);
    }
    if (index > -1) {
      state.routes.splice(index, 1);
    }
  },
  CHANGE_ALIVE_ROUTE(state, alive) {
    state.aliveRoute = alive;
  },
  SET_KEEP_ALIVE(state, data) {
    state.keepComponents = data;
  },
  PUSH_KEEP_ALIVE(state, data) {
    state.keepComponents.push(data);
  }
};

const actions = {
  add_route({ commit }, data) {
    commit("ADD_ROUTE", data);
  },
  change_alive_route({ commit }, data) {
    commit("CHANGE_ALIVE_ROUTE", data);
  },
  remove_route({ commit, dispatch }, data) {
    // dispatch("removeCache", data); //删除keepalive 的缓存
    commit("REMOVE_ROUTE", data);
  },
  set_route({ commit }, data) {
    commit("SET_ROUTE", data);
  },
  set_keep_alive({ commit }, data) {
    commit("SET_KEEP_ALIVE", data);
  },
  push_keep_alive({ commit }, data) {
    commit("PUSH_KEEP_ALIVE", data);
  }
};

export default {
  state,
  mutations,
  actions
};
