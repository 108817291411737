<template>
  <el-input
    v-bind="$attrs"
    :size="size"
    :clearable="clearable"
    :disabled="disabled"
    @input="onChange($event)"
    @blur="onBlur"
    type="text"
    :value="value"
  >
    <template v-for="slot in slots" :slot="slot">
      <slot :name="slot"></slot>
    </template>
  </el-input>
</template>
<script>
export default {
  inheritAttrs: true,
  name: "ShNumberInput",
  props: {
    value: {
      type: [String, Number]
    },
    max: {
      type: [String, Number],
      default: ""
    },
    min: {
      type: [String, Number],
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    precision: {
      type: [String, Number],
      default: 2
    },

    defaultValue: {
      type: String,
      default: ""
    },
    clearable: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 100
    },
    size: {
      type: String,
      default: "medium",
      validator: function (value) {
        return ["medium", "small", "mini"].includes(value);
      }
    }
  },
  model: {
    prop: "value",
    event: "change"
  },
  computed: {
    slots() {
      const tempSlots = this.$slots;
      return Object.keys(tempSlots);
    }
  },
  methods: {
    onChange(e) {
      let tempValue = "";
      if (this.precision == 0) {
        tempValue = e.replace(/[^\d]/g, "");
      } else {
        const reg = new RegExp(
          String.raw`^\D*(\d*(?:\.\d{0,${this.precision}})?).*$`,
          "g"
        );
        tempValue = e.replace(reg, "$1");
      }
      if (this.max !=='') {
        tempValue = tempValue > this.max ? this.max : tempValue;
      }

      this.$emit("change", tempValue);
    },
    onBlur(e) {
      const { value } = e.target;
      // let tempValue = ''
      // if (value === '') return
      let tempValue = String(Number(value));
      if (this.min) {
        tempValue = tempValue < this.min ? this.min : tempValue;
      }
      this.$emit("change", tempValue);
    }
  }
};
</script>
