
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  LOGIN_INFO,
  USER_NAME,
  TOKEN_TYPE,
  SIDEBAR_STATUS,
} from '@/config/mutation-types'

//storage

export function getStorage(key) {
  return  localStorage.getItem(key)
}
export function setStorage(key, value) {
  return localStorage.setItem(key, value, 7 * 24 * 3600 * 1000)
}
export function removeStorage(key) {
  return localStorage.removeItem(key)
}
export function getTokenType() {
  return localStorage.getItem(TOKEN_TYPE)
}
export function setTokenType(type) {
  return localStorage.setItem(TOKEN_TYPE,type)
}
//token
export function getToken() {
  return localStorage.getItem(ACCESS_TOKEN)||''
}

export function setToken(token) {
  return localStorage.setItem(ACCESS_TOKEN, token, 7 * 24 * 3600 * 1000)
}

export function removeToken() {
  return localStorage.removeItem(ACCESS_TOKEN)
}
//refreshToken
export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN)
}

export function setRefreshToken(token) {
  return localStorage.setItem(REFRESH_TOKEN, token, 7 * 24 * 3600 * 1000)
}

export function removeRefreshToken() {
  return localStorage.removeItem(REFRESH_TOKEN)
}

//登录时是否记住账号
export function getLoginInfo() {
  return localStorage.getItem(LOGIN_INFO)
}

export function setLoginInfo(login_info) {
  return localStorage.setItem(LOGIN_INFO, login_info, 7 * 24 * 3600 * 1000)
}

export function removeLoginInfo() {
  return localStorage.removeItem(LOGIN_INFO)
}

//username
export function getUserName() {
  return localStorage.getItem(USER_NAME)
}

export function setUserName(username) {
  return localStorage.setItem(USER_NAME, username, 7 * 24 * 3600 * 1000)
}

export function removeUserName() {
  return localStorage.removeItem(USER_NAME)
}

//sidebarStatus
export function getSidebarStatus() {
  return localStorage.getItem(SIDEBAR_STATUS)
}

export function setSidebarStatus(sidebarStatus) {
  return localStorage.setItem(SIDEBAR_STATUS, sidebarStatus, 7 * 24 * 3600 * 1000)
}

export function removeSidebarStatus() {
  return localStorage.removeItem(SIDEBAR_STATUS)
}