import { dictDataListByCode } from "@/api/dictionary";

//获取字典
export function getDicDataByCode(code, page = 0, size = 10000) {
  return new Promise((resolve, reject) => {
    dictDataListByCode({ code, page, size })
      .then((res) => {
        resolve(res);
      })
      .catch();
  });
}

//获取字典数据值对应的数据名称
export function filterDicLabel(value, dictionOption) {
  let text = "-";
  const tempObj = dictionOption.find((item) => item.code == value);
  text = tempObj ? tempObj.name : "-";
  return text;
}

export const period= [
  { label: "每天", value: "EVERY_DAY" },
  { label: "每周", value: "EVERY_WEEK" },
  { label: "每月", value: "EVERY_MONTH" }
  // { label: "不重复", value: "NO_REPEAT" }
]
export const weeks= [
  { label: "周一", value: "MONDAY" },
  { label: "周二", value: "TUESDAY" },
  { label: "周三", value: "WEDNESDAY" },
  { label: "周四", value: "THURSDAY" },
  { label: "周五", value: "FRIDAY" },
  { label: "周六", value: "SATURDAY" },
  { label: "周日", value: "SUNDAY" }
]