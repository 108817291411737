/**
 * 向后端请求用户的菜单，动态生成路由
 */
import { getCurrentUserNav } from "@/api/login";
import { baseRoutes } from "@/router/config";
import components from "@/router/components";

import store from "@/store";
const keepAlive=[]
const permission = {
  state: {
    routers: baseRoutes,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers;
      state.routers = baseRoutes.concat(routers);
    }
  },
  actions: {
    GenerateRoutes({ commit }) {
      return new Promise((resolve, reject) => {
        getCurrentUserNav()
          .then((res) => {
            permissionBtn(res);
            let currentRouter = listToTree(res);
            let constRouter = generator(currentRouter);
            constRouter.unshift(baseRoutes[0].children[0])
            baseRoutes[0].children =constRouter;
            console.log(baseRoutes)
            console.log(keepAlive)
            commit("SET_ROUTERS", constRouter);
            commit("SET_KEEP_ALIVE", keepAlive);
            resolve(res);
          })
          .catch((err) => {
            reject();
          });
      });
    }
  }
};
/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
const listToTree = (list, root = 0) => {
  let arr = [];
  list.forEach((item) => {
    // 2.首次传入null  判断list的parentId是否为空 如果为空就是一级节点
    if (item.parentId === root) {
      // 找到之后就要去找item下面有没有子节点  以 item.id 作为 父 id, 接着往下找
      const children = listToTree(list, item.id);
      if (children.length > 0) {
        // 如果children的长度大于0,说明找到了子节点
        item.children = children;
        // item.redirect=children[0].route
      }
      // 将item项, 追加到arr数组中
      if (item.type == "BUTTON") {
        return;
      }
      const meta = {
        title: item.name || item.menuName,
        icon: item.icon,
        hidden: !item.is_show==1,
        keepAlive: JSON.parse(item.extend).keepAlive
      };

      if(item.extend.keepAlive){
        keepAlive.push(item.code)
      }
      arr.push(
        Object.assign(item, { meta, redirect: item.extend.redirect || "" })
      );
    }
  });
  arr = arr.sort((a, b) => {
    return a.sort - b.sort;
  });
  return arr;
};
/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
/**
 * 权限按钮重组
 * @param list 源数组
 */
const permissionBtn = async (list) => {
  const permissions = [];
  const permissionList = [];
  list.forEach((item) => {
    if (item.type === "BUTTON") {
      const permissionParent = getParent(item, list);
      const actionEntitySet = {
        action: item.route,
        describe: item.name,
        id: item.id
      };
      //获取当前按钮的父级code 在不在permissionList里
      const index = permissionList.indexOf(permissionParent.code);
      if (index < 0) {
        //如果不在，push父级code到permissionList，并且初始化push权限对象到permissions
        permissionList.push(permissionParent.code);
        const obj = {
          actionEntitySet: [
            {
              action: item.route,
              describe: item.name,
              id: item.id
            }
          ],
          actionList: [item.route],
          actions: JSON.stringify(actionEntitySet),
          permissionId: permissionParent.code,
          permissionName: permissionParent.name,
          roleId: item.parentId
        };
        permissions.push(obj);
      } else {
        //如果存在，permissionList不变化，在permissions相应index项目的actionEntitySet和actionList字段push进相应的值
        permissions[index].actionEntitySet.push(actionEntitySet);
        permissions[index].actionList.push(item.route);
        permissions[index].actions = JSON.stringify(
          permissions[index].actionEntitySet
        );
      }
    }
  });

  store.commit(
    "SET_ROLES",
    Object.assign(store.getters.roles, {
      permissions,
      permissionList
    })
  );
};
//获取父级菜单
const getParent = (children, parents) => {
  return parents.find((item) => {
    return item.id == children.parentId;
  });
};
//树形转数组
const treeToList = (tree) => {
  let arr = [];
  tree.map((item) => {
    arr.push(item);
    if (item.children && item.children.length) {
      arr = arr.concat(treeToList(item.children));
    } else {
      arr.push(item);
    }
  });
  return arr;
};
export const generator = (routerMap, parent) => {
  return routerMap.map((item) => {
    
    const { title, hidden, icon, keepAlive } = item.meta || {};
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: item.route || `${(parent && parent.route) || ""}/${item.key}`,
      // 路由名称，建议唯一
      name: item.component ||item.code,
      component:
        components[item.component || item.key] ||
        (() => import(`@/views/${item.component}`)),
      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: title,
        icon: icon || undefined,
        keepAlive: keepAlive,
        permissionId: item.id,
        hidden
      },
      // 用于权限分配id
      key: item.id || 0,
      title: title
    };
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith("http")) {
      currentRouter.path = currentRouter.path.replace("//", "/");
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect);
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter);
    }
    return currentRouter;
  });
};
export default permission;
