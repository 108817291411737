export const ACCESS_TOKEN = 'Access-Token'
export const REFRESH_TOKEN = 'Refresh-Token'
export const USER_NAME = 'User-Name'
export const LOGIN_INFO = 'Login-Info'
export const SIDEBAR_STATUS = 'sidebarStatus'
export const LANGUAGE = 'language'
export const TOKEN_TYPE = 'OaTokenType'

//职位类型

export const positionTypes=['普通用户','领队','群主','版主']
