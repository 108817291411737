<template>
  <div class="upload-container">
    <el-button
      :style="{ background: color, borderColor: color }"
      icon="el-icon-upload"
      type="primary"
      @click="dialogVisible = true"
    >
      上传
    </el-button>
    <el-dialog :visible.sync="dialogVisible">
      <!-- <el-upload
        :multiple="true"
        name="file"
        :file-list="fileList"
        :show-file-list="true"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :before-upload="beforeUpload"
        class="editor-slide-upload"
        :action="baseUrl + '/upload'"
        list-type="picture-card"
      >
        <el-button size="small" type="primary"> 点击上传 </el-button>
      </el-upload> -->
      <dragUpload
        :multiple="true"
        :limit="10"
        ref="cover"
        tips="请上传png,jpg格式图片"
        v-model="fileList"
      />
      <el-button @click="dialogVisible = false"> 取消 </el-button>
      <el-button type="primary" @click="handleSubmit"> 确认 </el-button>
    </el-dialog>
  </div>
</template>

<script>
// import { getToken } from 'api/qiniu'
// import shUpload from "@/components/shUpload.vue";
import dragUpload from "@/components/dragUpload.vue";
export default {
  name: "EditorSlideUpload",
  props: {
    color: {
      type: String,
      default: "#1890ff",
    },
  },
  components: { dragUpload },
  data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: [],
    };
  },
  methods: {
    checkAllSuccess() {
      // return Object.keys(this.listObj).every(
      //   (item) => this.listObj[item].hasSuccess
      // );
      for (let index in this.fileList) {
        if (this.fileList[index].status != "success") {
          return false;
        }
      }
      return true;
    },
    handleSubmit() {
      // const arr = Object.keys(this.listObj).map((v) => this.listObj[v]);
      if (!this.checkAllSuccess()) {
        this.$message(
          "Please wait for all images to be uploaded successfully. If there is a network problem, please refresh the page and upload again!"
        );
        return;
      }
      // this.$emit("successCBK", arr);
      this.$emit("successCBK", this.fileList);
      this.listObj = {};
      this.fileList = [];
      this.dialogVisible = false;
    },
    handleSuccess(response, file) {
      const uid = file.uid;
      const objKeyArr = Object.keys(this.listObj);
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        this.listObj[objKeyArr[i]].url = response.data.urk;
        this.listObj[objKeyArr[i]].hasSuccess = true;
        return;
        // if (this.listObj[objKeyArr[i]].uid === uid) {
        //   this.listObj[objKeyArr[i]].url = response.data.urk;
        //   this.listObj[objKeyArr[i]].hasSuccess = true;
        //   return;
        // }
      }
    },
    handleRemove(file) {
      const uid = file.uid;
      const objKeyArr = Object.keys(this.listObj);
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]];
          return;
        }
      }
    },
    beforeUpload(file) {
      const _self = this;
      const _URL = window.URL || window.webkitURL;
      const fileName = file.uid;
      this.listObj[fileName] = {};
      console.log(file);
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = function () {
          _self.listObj[fileName] = {
            hasSuccess: false,
            uid: file.uid,
            width: this.width,
            height: this.height,
          };
        };
        resolve(true);
      });
    },

    onUpload(event) {
      event.forEach((element) => {
        element.width = this.width;
        element.height = this.height;
      });
      this.fileList = event;
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-slide-upload {
  margin-bottom: 20px;
  ::v-deep .el-upload--picture-card {
    width: 100%;
  }
}
</style>
