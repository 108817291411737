<template>
  <div class="tac pt20">
    <el-pagination
      class="hidden-sm-and-down"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-count="pageCount"
      :background="true"
      :page-sizes="[5, 10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: "ShPagination",
  data() {
    return {};
  },
  props: {
    pageSize: {
      type: Number,
      default: 10,
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  methods:{
    handleSizeChange(val){
      this.$emit('sizeChange',{
        size:val,
        page:1
      })
    },
    handleCurrentChange(val){
      console.log(val)
      this.$emit('sizeChange',{
        size:this.pageSize,
        page:val
      })
    }
  }
};
</script>