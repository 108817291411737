<template>
  <ul
    class="el-dropdown-menu el-dropdown-menu--default"
    v-bind="$attrs"
    style="outline: none;background-color: #fff;"
  >
    <li class="el-dropdown-menu__item" @click.stop="refresh">
      <i class="el-icon-refresh"></i>重新加载
    </li>
    <li
      class="el-dropdown-menu__item"
      :class="{
        'is-disabled': isOnly || activeIndex == 0
      }"
      @click.stop="onCloseActive"
    >
      <i class="el-icon-close"></i>
      关闭当前
    </li>
    <li
      class="el-dropdown-menu__item"
      :class="{
        'is-disabled': activeIndex < 2
      }"
      @click.stop="onCloseLeft"
    >
      <i class="el-icon-back"></i>
      关闭左侧
    </li>
    <li
      class="el-dropdown-menu__item"
      :class="{
        'is-disabled': activeIndex == tabLength - 1
      }"
      @click.stop="onCloseRight"
    >
      <i class="el-icon-right"></i>
      关闭右侧
    </li>
    <li
      class="el-dropdown-menu__item"
      :class="{
        'is-disabled': isOnly || activeIndex < 2
      }"
      @click.stop="onCloseOther"
    >
      <i class="el-icon-rank"></i>
      关闭其他
    </li>
    <li
      class="el-dropdown-menu__item"
      :class="{
        'is-disabled': isOnly
      }"
      @click.stop="onCloseAll"
    >
      <i class="el-icon-minus"></i>
      关闭全部
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "navTabsMenu",
  computed: {
    ...mapGetters(["aliveRoute", "routes"]),
    activeIndex() {
      const index = this.routes.findIndex((item) => {
        return item.fullPath == this.fullPath;
      });
      return index;
    },
    tabLength() {
      return this.routes.length;
    },
    isOnly() {
      return this.routes.length == 1;
    }
  },
  props: {
    fullPath: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "visible",
    event: "change"
  },
  data() {
    return {};
  },
  inject: ["reload"],
  methods: {
    ...mapActions(["remove_route", "change_alive_route", "set_route"]),
    // 刷新
    refresh() {
      this.reload();
      this.onChange();
    },
    //关闭当前
    onCloseActive() {
      if (this.isOnly) return;
      this.remove_route(this.$route.fullPath);
      this.$router.push(this.routes[this.routes.length - 1].fullPath);
      this.onChange();
    },
    //关闭左侧
    onCloseLeft() {
      if (this.activeIndex < 2) return;
      this.routes.splice(0, this.activeIndex);
      this.set_route(this.routes);
      this.$router.push(this.routes[0].fullPath);
      this.onChange();
    },
    //关闭右侧
    onCloseRight() {
      console.log(this.activeIndex);
      if (this.activeIndex == this.tabLength - 1) return;
      this.routes.splice(this.activeIndex + 1, this.tabLength - 1);
      this.set_route(this.routes);
      this.$router.push(this.routes[this.routes.length - 1].fullPath);
      this.onChange();
    },
    //关闭其他
    onCloseOther() {
      if (this.isOnly || this.activeIndex < 2) return;
      const onlyRoute = this.routes.find((item) => {
        return item.fullPath == this.routes[this.activeIndex].fullPath;
      });
      this.set_route([onlyRoute]);
      this.$router.push(this.routes[0].fullPath);
      this.onChange();
    },
    //关闭全部
    onCloseAll() {
      if (this.isOnly) return;
      const onlyRoute = this.routes.find((item) => {
        return item.fullPath == this.routes[this.activeIndex].fullPath;
      });
      this.set_route([onlyRoute]);
      this.$router.push(this.routes[0].fullPath);
      this.onChange();
    },

    onChange() {
      this.$emit("change");
    }
  }
};
</script>
