module.exports = {

    title: '后台管理系统',
    companyName: '幸途',
    LOGO: require('@/assets/logo.png'),
    /**
     * @type {boolean} true | false
     * @description Whether show the logo in sidebar
     */
    showAsidebarLogo: true, //是否显示logo
    needPermission:true,//是否需要校验用户token
}