<template>
  <div>
    <Logo :collapse="collapse" />
    <Meun :collapse="collapse" />
  </div>
</template>

<script>
import Logo from "./logo.vue";
import Meun from "./meun.vue";
export default {
  name: "AppAside",
  data() {
    return {};
  },
  props: {
    collapse: {
      type: Boolean,
      default: false
    }
  },
  components: { Logo, Meun },
  methods: {}
};
</script>
