import request from '@/utils/request'
const api = {
  organizations: '/organizations/',
}

// 新增部门
export function addOrg(parameter) {
  return request({
    url: api.organizations,
    method: 'post',
    data: parameter
  })
}

//启用部门
export function enableOrg(parameter) {
  return request({
    url: `${api.organizations}actions/enable/`,
    method: 'POST',
    data: parameter
  })
}
//禁用部门
export function disableOrg(parameter) {
  return request({
    url: `${api.organizations}actions/disable/`,
    method: 'POST',
    data: parameter
  })
}
// 删除部门
export function deleteOrg(parameter) {
  return request({
    url: `${api.organizations}${parameter.id}/`,
    method: 'DELETE',
    data: {}
  })
}
// 更新部门
export function updateOrg(parameter) {
  return request({
    url: `${api.organizations}${parameter.identifier}/`,
    method: 'put',
    data: parameter
  })
}
// 部门详细信息
export function infoOrg(parameter) {
  return request({
    url: `${api.organizations}${parameter.id}/`,
    method: 'GET',
    params: {}
  })
}
// 部门列表
export function listOrg(parameter) {
  return request({
    url: `${api.organizations}`,
    method: 'GET',
    params: parameter
  })
}
//获取部门的树形结构
export function getOrgTree(parameter) {
  return request({
    url: `${api.organizations}tree/`,
    method: 'GET',
  })
}
//查询组织变更信息
export function getOrgChange(parameter) {
  return request({
    url: `${api.organizations}${parameter.id}/changeRecords`,
    method: 'GET',
  })
}
//二级通讯录
export function secondLevelOrg(parameter) {
  return request({
    url: `${api.organizations}second-level/${parameter.id}/`,
    method: 'GET',
    params: parameter
  })
}
//一级通讯录
export function firstLevelOrg(parameter) {
  return request({
    url: `${api.organizations}first-level/`,
    method: 'GET',
    data: parameter
  })
}