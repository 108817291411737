/**
 * 引用工具函数：
 * isRegExp ： 判断是否是正则表达式
 * remove: 从数组中删除某一项
 * getFirstComponentChild：
 */
function remove(arr, item) {
  const len = arr.length;
  if (len) {
    // fast path for the only / last item
    if (item === arr[len - 1]) {
      arr.length = len - 1;
      return;
    }
    const index = arr.indexOf(item);
    if (index > -1) {
      return arr.splice(index, 1);
    }
  }
}
function isRegExp(v) {
  return Object.prototype.toString.call(v) === "[object RegExp]";
}
/**
 *第一个行有个注释 @flow，基于FLOW去做的类型检查
 * 表示引用一个复杂类型，[]表示可以通过key进行索引，？表示值的类型是可选的
 **/

/**
 * 获取组件名称：
 * 如果option中有定义name属性则直接返回
 * 否则返回tag
 */
function getComponentName(opts) {
  console.log(opts);
  return opts && (opts.Ctor.options.name || opts.tag);
}

function isEmpty(...str) {
  return str.some((i) => i === undefined || i === null || i === "");
}
function getFirstComponentChild(children) {
  if (Array.isArray(children)) {
    return children.find(
      (c) =>
        !isEmpty(c) &&
        (!isEmpty(c.componentOptions) || (c.isComment && c.asyncFactory))
    );
  }
}
/**
 * 匹配规则
 */
function matches(pattern, name) {
  if (Array.isArray(pattern)) {
    return pattern.includes(name);
  } else if (typeof pattern === "string") {
    return pattern.split(",").indexOf(name) > -1;
  } else if (isRegExp(pattern)) {
    return pattern.test(name);
  }
  /* istanbul ignore next */
  return false;
}
/**
 * 修剪缓存
 */
function pruneCache(keepAliveInstance, filter) {
  const { cache, keys, _vnode } = keepAliveInstance;
  for (const key in cache) {
    const cachedNode = cache[key];
    if (cachedNode) {
      // const name = getComponentName(cachedNode.componentOptions);
      // if (name && !filter(name)) {
      //   pruneCacheEntry(cache, key, keys, _vnode);
      // }
      //循环cache 缓存的所有组件，如果不在include里，则删除
      if (!filter(key)) {
        pruneCacheEntry(cache, key, keys, _vnode);
      }
    }
  }
}
/**
 * 修剪缓存入口
 */
function pruneCacheEntry(cache, key, keys, current) {
  const cached = cache[key];
  // 主动执行某个组件的destory，触发destroy钩子，达到销毁目的，然后移除缓存中的key-value
  if (cached && (!current || cached.tag !== current.tag)) {
    cached.componentInstance.$destroy();
  }
  cache[key] = null;
  remove(keys, key);
}
function getRouterViewCacheKey(route) {
  // 这里可以控制自己想要的key
  return route.fullPath;
}
const patternTypes = [];
export default {
  name: "keep-alive",
  // 抽象组件，判断当前组件虚拟dom是否渲染成真实dom的关键
  abstract: true,
  // 定义include、exclude及max属性
  // include - 字符串或正则表达式。只有名称匹配的组件会被缓存。
  // exclude - 字符串或正则表达式。任何名称匹配的组件都不会被缓存。
  // max - 数字。最多可以缓存多少组件实例。
  props: {
    include: patternTypes,
    exclude: patternTypes,
    max: [String, Number]
  },
  created() {
    // 组件创建时创建缓存对象
    this.cache = Object.create(null); // 缓存虚拟dom
    this.keys = []; // 缓存的虚拟dom的键集合
  },
  destroyed() {
    // 销毁时清除所有缓存
    for (const key in this.cache) {
      pruneCacheEntry(this.cache, key, this.keys);
    }
  },
  mounted() {
    // 监听include和exclue，使其支持双向绑定。对include和exclude参数进行监听，然后实时地更新（删除）this.cache对象数据
    this.$watch("include", (val) => {
      pruneCache(this, (name) => matches(val, name));
    });
    this.$watch("cache", {
      deep: true,
      handler: (val) => {
        console.log(val);
      }
    });
    this.$watch("exclude", (val) => {
      pruneCache(this, (name) => !matches(val, name));
    });
  },

  render() {
    // $slots.default表示slot中的所有子组件（包括换行）
    const slot = this.$slots.default;
    // 获取第一个组件（这就是为什么keep-alive中只允许渲染一个直属子组件，而不能用v-for的原因）
    const vnode = getFirstComponentChild(slot);
    const componentOptions = vnode && vnode.componentOptions;
    if (componentOptions) {
      // 获取子组件名称
      // const name = getComponentName(componentOptions);
      // 获取缓存key值
      const key = getRouterViewCacheKey(this.$route);
      // 验证组件名称的name是否包含在include或者不在exclude中
      const { include, exclude } = this;
      if (
        // not included
        (include && (!key || !matches(include, key))) ||
        // excluded
        (exclude && key && matches(exclude, key))
      ) {
        // 不通过缓存获取，直接加载组件
        console.log("无需缓存");
        return vnode;
      }
      const { cache, keys } = this;
      // const key = getRouterViewCacheKey(this.$route);
      // 如果key队友的vnode存在，则更新key值
      if (cache[key]) {
        vnode.componentInstance = cache[key].componentInstance;
        // make current key freshest
        remove(keys, key);
        keys.push(key); // 调整key排序
        console.log("已缓存");
      } else {
        // 否则将vnode存入缓存
        cache[key] = vnode;
        keys.push(key);
        // 如果超出max则将第一个缓存的vnode移除
        if (this.max && keys.length > parseInt(this.max)) {
          pruneCacheEntry(cache, keys[0], keys, this._vnode);
        }
        console.log("未缓存");
      }
      this.cache = cache;
      this.keys = keys;
      vnode.data.keepAlive = true;
    }
    return vnode || (slot && slot[0]);
  }
};
