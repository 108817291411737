import Vue from 'vue'
import Router from 'vue-router'
import {
  baseRoutes
} from './config'
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)
const router = new Router({
  mode: 'hash',
  routes: baseRoutes
})
export default router