<!-- 父组件插槽使用 -->
<!-- <div slot="operate" slot-scope="{row,index}">
  <div>{{index}}{{ row }}</div>
</div> -->

<template>
  <div class="shTable" v-if="show">
    <el-table v-bind="$attrs" v-on="$listeners" >
      <template v-for="(item, index) in tableColumns">
        <sh-table-column
          v-if="item.children && item.show"
          :item="item"
        ></sh-table-column>
        <el-table-column v-bind="item" :key="index" v-else-if="item.show">
          <template slot="header">
            <span class="customTitle" v-if="index == tableColumns.length - 1">
              {{ item.label }}
              <el-popover placement="bottom" width="160" v-if="showFilter">
                <p>需要显示的列</p>
                <div class="checkBox">
                  <template v-for="(column, index) in tableColumns">
                    <el-checkbox
                      v-if="index < tableColumns.length - 1"
                      :checked="column.show"
                      @change="onChange($event, index)"
                      :key="index"
                      >{{ column.label }}</el-checkbox
                    >
                  </template>
                </div>
                <img
                  @click.stop=""
                  slot="reference"
                  class="filterIcon"
                  src="../../src/assets/images/filter.png"
                  alt=""
                />
              </el-popover>
            </span>
            <span v-else>
              {{ item.label }}
            </span>
          </template>
          <template v-slot="scope" v-if="item.scopeSlot">
            <slot
              :name="item.scopeSlot"
              :row="scope.row"
              :index="scope.$index"
            ></slot>
          </template>
          <template v-else-if="item.render" v-slot="scope">
            <render-dom
              :render="() => item.render(scope.row, scope.$index)"
            ></render-dom>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <div v-if="queryParams">
      <sh-pagination
        @sizeChange="handleSizeChange"
        :currentPage="queryParams.page"
        :pageCount="queryParams.total"
        :pageSize="queryParams.size"
      ></sh-pagination>
    </div>
  </div>
</template>

<script>
import shPagination from "./sh-pagination.vue";
import ShTableColumn from "./sh-table-column.vue";
export default {
  name: "ShTable",
  components: {
    shPagination,
    // 函数式组件注册
    renderDom: {
      functional: true,
      props: {
        render: Function,
      },
      render(createElement, renDom) {
        return <div>{renDom.props.render()}</div>;
      },
    },
    ShTableColumn,
  },
  props: {
    showFilter: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      default() {
        return [
          {
            prop: "title",
            label: "工资条名称",
            align: "center",
            // width: "120"
          },
          {
            prop: "num",
            label: "员工姓名",
            align: "center",
            sortable: true,
          },
          {
            prop: "operate",
            label: "操作",
            align: "center",
            scopeSlot: "operate",
            render: (m, index) => {
              return (
                <el-button type="text" class="cdanger" size="small">
                  详情
                </el-button>
              );
            },
          },
        ];
      },
    },
    queryParams: {
      type: [Object, Boolean],
      default() {
        return {
          page: 0,
          size: 10,
          total: 1,
        };
      },
    },
  },
  computed: {
    tableColumns: {
      get() {
        return this.columns.map((item) => {
          return { ...item, show: true };
        });
      },
      set(val) {},
    },
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    handleSizeChange(e) {
      this.$emit("paginationChange", e);
    },
    onChange(e, index) {
      let item = this.tableColumns[index];
      item.show = e;
      this.$set(this.tableColumns, index, item);
      this.$forceUpdate();
    },
    doLayout() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

<style>
.shTable .el-table__header-wrapper {
  position: relative;
}
.customTitle {
  /* position: relative; */
}
.filterIcon {
  width: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}
.checkBox {
  padding: 10px;
  border: 1px solid #e4e4e4;
  margin-top: 10px;
}
</style>
