export default {
    layout: () => import('@/layout/index.vue'),
    Redirect: () => import('@/views/dashboard/redirect.vue'),
    workplace: () => import('@/views/dashboard/index.vue'),
    User: () => import('@/views/user/index'),
    Login: () => import('@/views/user/login'),
    Firstlogin: () => import('@/views/user/firstLogin'),
    SystemMeun: () => import('@/views/system/meun/list.vue'),
    Roles: () => import('@/views/system/role/list.vue'),
    Partment: () => import('@/views/system/partment/index.vue'),
    PartmentEdit: () => import('@/views/system/partment/edit.vue'),
    PartmentInfo: () => import('@/views/system/partment/info.vue'),
    SystemUser: () => import('@/views/system/user/list.vue'),
    SystemDictionary: () => import('@/views/system/dictionary/index.vue'),
    SystemDictionaryData: () => import('@/views/system/dictionary/dataList.vue'),
    SystemSetting: () => import('@/views/system/setting/index.vue'),
    //活动分类
    ClassifyList: () => import('@/views/classify/list'),
    ClassifyCreate: () => import('@/views/classify/edit'),
    AwardsList: () => import('@/views/awards/list'),
    AwardsCreate: () => import('@/views/awards/edit'),
    ActivityList: () => import('@/views/activity/list'),
    ActivityCreate: () => import('@/views/activity/edit'),
    ActivityInfo: () => import('@/views/activity/info'),
    //社群
    GroupList: () => import('@/views/group/list'),
    GroupApplyList: () => import('@/views/group/groupApply'),
    //分类标签
    TagsList: () => import('@/views/tags/list'),
    //订单
    OrderList: () => import("@/views/order/list.vue"),
    OrderCreate: () => import("@/views/order/edit.vue"),
    OrderInfo: () => import("@/views/order/info.vue"),
    //资讯
    ArticleList: () => import("@/views/article/list.vue"),
    ArticleCreate: () => import("@/views/article/edit.vue"),
    ArticleInfo: () => import("@/views/article/info.vue"),
    //常见问题
    QuestionList: () => import("@/views/question/list.vue"),
    QuestionCreate: () => import("@/views/question/edit.vue"),
    //广告位
    AdvertList: () => import("@/views/advert/list.vue"),
    AdvertCreate: () => import("@/views/advert/edit.vue"),
    //CRM
    CustomList: () => import('@/views/CRM/custom/list.vue'),
    CustomCreate: () => import('@/views/CRM/custom/edit.vue'),
    CustomInfo: () => import('@/views/CRM/custom/info.vue'),

}